// Import necessary dependencies and components
import { useState, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";

// Define the About component
const About = ({ data, page, onRerender }) => {
  // Use useRef for accessing the JoditEditor instance
  const editor = useRef(null);

  // State variables for form data, loading state, and alerts
  const [alert, setAlert] = useState("none");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle input changes in the form fields
  const handleInputChange = (e) => {
    // Extract field name and value from the event
    const name = e.target.name;
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    // Update formData state with the new values
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    // Prevent default form submission behavior
    e.preventDefault();

    // Set loading state to true
    setLoading(true);

    // Create a FormData object to send mixed data (text and files)
    const dataToSend = new FormData();

    // Append data to FormData object if available
    if (formData.heading) dataToSend.append("heading", formData.heading);
    if (formData.title) dataToSend.append("title", formData.title);
    if (formData.content) dataToSend.append("content", formData.content);
    if (formData.image) dataToSend.append("image", formData.image);

    try {
      // Send a PUT request to update data
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/${page}/aboutcompany`,
        dataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        },
      );

      // Display success alert
      setAlert("success");
      onRerender();
      // Reset the alert after a delay
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Display failure alert
      setAlert("failed");
      console.error(e);
      // Reset the alert after a delay
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } finally {
      // Set loading state back to false
      setLoading(false);
    }
  };

  // Return JSX for the About component
  return (
    <div className="container mt-4 border p-3 shadow">
      <div className="row">
        <div className="col-md-12">
          {/* Form for editing company information */}
          <form onSubmit={handleSubmit}>
            <h2 className="mb-5 fw-bold">Home / {data?.heading}</h2>

            {/* Input field for Heading */}
            <div className="form-group m-3">
              <label className="fw-semibold fs-5 form-label">Heading:</label>
              <input
                className="form-control"
                type="text"
                name="heading"
                defaultValue={data?.heading}
                onChange={handleInputChange}
              />
            </div>

            {/* Input field for Title */}
            <div className="form-group m-3">
              <label className="fw-semibold fs-5 form-label">Title:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.title}
                onChange={handleInputChange}
              />
            </div>

            {/* JoditEditor for editing content */}
            <div className="form-group m-3">
              <label className="fw-semibold fs-5 form-label">Content:</label>
              <JoditEditor
                ref={editor}
                value={data?.content}
                tabIndex={1}
                onBlur={(newContent) =>
                  setFormData({
                    ...formData,
                    content: newContent,
                  })
                }
              />
            </div>

            {/* Input field for Image */}
            <div className="form-group m-3">
              <label className="fw-semibold fs-5 form-label">Image:</label>
              <div className="row">
                {/* Display preview of the selected image */}
                {formData?.image ? (
                  <img
                    src={URL.createObjectURL(formData?.image)}
                    className="col-md-3 img-fluid"
                  />
                ) : (
                  <img
                    alt="no image"
                    src={`${process.env.REACT_APP_API_URL}/${data?.image}`}
                    className="col-md-3 img-fluid"
                  />
                )}
                {/* Input field for selecting a new image */}
                <div className="col">
                  <input
                    className="form-control"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            {/* Display success or failure alerts */}
            {alert === "success" && (
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                About the company modified Successfully!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {alert === "failed" && (
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}

            {/* Submit button */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4 "
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Export the About component
export default About;
