// Importing necessary dependencies from React and Axios
import { useState, useEffect } from "react";
import axios from "axios";

// Importing components for the admin interface
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Blog from "../../components/Admin/Blog";
import PageTransition from "../../components/PageTransition";

// Functional component 'Blogs'
const Blogs = () => {
  // State variables to manage the selected action, blog data, and loading status
  const [selected, setSelected] = useState("modify");
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };
  // Effect hook to fetch blog data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/blogs",
        );
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors if the data fetch fails
        console.error(err);
      }
    };

    // Calling the fetch data function
    fetchdata();

    // Cleanup function to reset data on component unmount
    return () => {
      setData([]);
    };
  }, [rerenderCount]);

  // Rendering the component content after data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin navigation bar component */}
          <AdminNavbar page={"blogs"} />
          <div className="col mt-2 p-4">
            <div className="d-flex gap-4">
              {/* Button to select 'Modify Blogs' action */}
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("modify")}
              >
                Modify Blogs
              </button>
              {/* Button to select 'Add Blog' action */}
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("add")}
              >
                Add Blog
              </button>
            </div>
            <div className="mt-5">
              {/* Conditional rendering based on the selected action */}
              {selected === "modify" && (
                <div>
                  <h2 className="fw-bold">Modify Blog</h2>
                  <div className="mt-4">
                    {/* Mapping through the blog data to render individual 'Blog' components */}
                    {data?.map((dat, index) => {
                      return (
                        <Blog
                          key={index}
                          data={dat}
                          index={index}
                          type={"modify"}
                          onRerender={handleRerender}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {selected === "add" && (
                <Blog type={"add"} onRerender={handleRerender} />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // Rendering null while data is being loaded
};

// Exporting the 'Blogs' component as the default export
export default Blogs;
