// Functional component 'Faq' that takes 'data' and 'index' props
const Faq = ({ data, index }) => {
  // Rendered component for a single FAQ item
  return (
    <div className="accordion-item mb-md-4 mb-3">
      {/* FAQ header with a collapsible button */}
      <h2 className="accordion-header  ">
        <button
          className="accordion-button p-md-4 p-3 collapsed faq-title"
          type="button"
          // Data attributes to control the collapse behavior
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded="false"
          aria-controls={`collapse${index}`}
        >
          {/* FAQ title with dynamic content from 'data' prop */}
          {data?.title}
        </button>
      </h2>

      {/* FAQ content section with a unique identifier */}
      <div id={`collapse${index}`} className="accordion-collapse collapse">
        {/* FAQ content with dangerously set HTML from 'data' prop */}
        <div
          className="accordion-body faq-content"
          dangerouslySetInnerHTML={{ __html: data?.content }}
        ></div>
      </div>
    </div>
  );
};

// Export the Faq component as the default export
export default Faq;
