// Import necessary components and libraries
import Footer from "../components/Footer";
import Header from "../components/Header";
import BreadCrum from "../components/BreadCrum";
import Faq from "../components/Faq";
import axios from "axios";
import { useState, useEffect } from "react";
import Reveal from "../components/Reveal";
import PageTransition from "../components/PageTransition";
import Spinner from "../components/Spinner";

// Define the Faqs functional component
const Faqs = () => {
  // State to hold the fetched data
  const [data, setData] = useState([]);
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/faqs",
        );

        // Update state with the fetched data and set loaded to true
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData([]);
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // Render the component
  return (
    // Render content when data is loaded
    <PageTransition>
      {/* Render the Header component with the "faqs" page */}
      <Header page={"faqs"} />
      {/* Render the BreadCrum component with the "faqs" page title */}
      <BreadCrum page={"faqs"} title={"FAQ's"} />
      {loaded ? (
        // Render content when data is loaded
        <Reveal>
          {/* Render the FAQ section */}
          <div className="bg-theme py-md-4 py-3">
            <div className="container-md p-md-4 p-3">
              <div className="row justify-content-center mb-4">
                <div className="col-12 col-lg-10">
                  {/* FAQ introduction section */}
                  <div className="faq-intro">
                    Welcome to our FAQ section, where we address the most common
                    inquiries and provide comprehensive answers to help you
                    navigate our services with ease.
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                  {/* Render FAQs in an accordion */}
                  {data?.length !== 0 ? (
                    <div className="accordion">
                      {data?.map((dat, index) => {
                        // Render individual FAQ components
                        return <Faq data={dat} index={index} key={index} />;
                      })}
                    </div>
                  ) : (
                    // Display message if no FAQs are available
                    <div className="no-content subheading">
                      No Faqs to Display
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Reveal>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}
      {/* Render the Footer component */}
      <Footer />
    </PageTransition>
  ); // Return null if data is not loaded
};

// Export the Faqs component as the default export
export default Faqs;
