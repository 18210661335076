// Importing necessary dependencies from React and Axios
import { useState, useEffect, useRef } from "react";
import axios from "axios";

// Importing components for the admin interface
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Career from "../../components/Admin/Career";
import PageTransition from "../../components/PageTransition";
import JoditEditor from "jodit-react";

// Functional component 'Careers'
const Careers = () => {
  // State variables to manage the career data, selected tab, and loading status
  const [selected, setSelected] = useState("headings");
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };

  // Effect hook to fetch career data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/careers",
        );

        // Setting the fetched data and marking it as loaded
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors if the data fetch fails
        console.error(err);
      }
    };

    // Calling the fetch data function
    fetchdata();

    // Cleanup function to reset data on component unmount
    return () => {
      setData([]);
    };
  }, [rerenderCount]);

  // Rendering the component content after data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin navigation bar component */}
          <AdminNavbar page={"careers"} />
          <div className="col mt-2 p-4">
            <div className="d-flex gap-4">
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("headings")}
              >
                Career Headings
              </button>
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("modify")}
              >
                Modify Careers
              </button>
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("add")}
              >
                Add Career
              </button>
            </div>
            <div className="mt-5">
              {/* Conditional rendering based on the selected tab */}
              {selected === "headings" && (
                <CareerHeadings
                  data={data?.careerHeadings?.titleFields}
                  onRerender={handleRerender}
                />
              )}
              {selected === "modify" && (
                <div>
                  <h2 className="fw-bold">Modify Career</h2>
                  <div className="mt-4">
                    {/* Mapping through the career data to render individual 'Career' components */}
                    {data?.careers?.map((dat, index) => {
                      return (
                        <Career
                          index={index}
                          key={index}
                          data={dat}
                          type={"modify"}
                          onRerender={handleRerender}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {selected === "add" && (
                <Career type={"add"} onRerender={handleRerender} />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // Rendering null while data is being loaded
};

// Functional component 'CareerHeadings'
const CareerHeadings = ({ data, onRerender }) => {
  // State variables to manage the alert type, form data, and loading status
  const [alert, setAlert] = useState("none");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Making a PUT request to modify career headings
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/careers/headings`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      // Setting success alert and resetting after a timeout
      setAlert("success");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      onRerender();
    } catch (e) {
      // Setting failed alert and resetting after a timeout
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // Rendering the component content
  return (
    <div className="container mt-4 border p-3 shadow">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <h2 className="mb-5 fw-bold">Careers / Headings</h2>

            {/* Form input for heading */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Heading:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.heading}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heading: e.target.value,
                  })
                }
              />
            </div>

            {/* Jodit editor for the 'title' field */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Title:</label>
              <JoditEditor
                ref={editor}
                value={data?.title}
                tabIndex={1}
                onBlur={(newContent) =>
                  setFormData({
                    ...formData,
                    title: newContent,
                  })
                }
              />
            </div>

            {/* Alert messages based on submission status */}
            {alert === "success" && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                Headings modified Successfully!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {alert === "failed" && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}

            {/* Submit button with loading state */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4"
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Exporting the 'Careers' component as the default export
export default Careers;
