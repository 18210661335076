// Import React and necessary components
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageTransition from "../components/PageTransition";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";

// Define the SingleBlog functional component
const SingleBlog = () => {
  // State to hold the fetched data
  const [data, setData] = useState({});
  // Get the 'title' parameter from the URL using useParams hook
  const { title } = useParams();
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // Function to format date in a readable format
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
    return formattedDate;
  }

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint based on the 'title' parameter
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/blogs/" + title,
        );
        // Update state with the fetched data and set loaded to true
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData({});
    };
  }, [title]); // Add 'title' to the dependency array to re-fetch data when 'title' changes

  // Render the component
  return (
    <PageTransition>
      {/* Render the Header component with the "blogs" page */}
      <Header page={"blogs"} />
      {loaded ? (
        // Render content when data is loaded
        <div className="container p-md-4 p-3 my-md-4 my-3">
          {/* Display blog title */}
          <p className="blogs-heading2">{data?.title}</p>
          <hr className="border border-1 opacity-75 my-3" />
          {/* Display author and publication date */}
          <div className="d-flex justify-content-between">
            <p className="blogs-info2">{data?.author}</p>
            <p className="blogs-info2">
              Published : {formatDate(data?.createdAt)}
            </p>
          </div>
          <hr className="border border-1 opacity-75 my-1" />
          {/* Display blog content */}
          <div className="row my-4">
            {/* Render HTML content dangerously using dangerouslySetInnerHTML */}
            <div
              className="order-2 order-lg-1 col-12 col-md-10 col-lg-7 blogs-content pe-lg-4 mx-auto"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
            {/* Display images and video */}
            <div className="order-1 order-lg-2 col-12 col-md-10 col-lg-5 mx-auto">
              {data?.image1 && (
                <img
                  src={`${process.env.REACT_APP_API_URL}/${data?.image1}`}
                  className="mb-4 img-fluid"
                />
              )}
              {data?.image2 && (
                <img
                  src={`${process.env.REACT_APP_API_URL}/${data?.image2}`}
                  className="mb-4 img-fluid"
                />
              )}
              {data?.videolink && (
                <iframe
                  className="w-100"
                  height="300"
                  src={data?.videolink}
                  title="Blog Video"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}
      {/* Render the Footer component */}
      <Footer />
    </PageTransition>
  );
};

// Export the SingleBlog component as the default export
export default SingleBlog;
