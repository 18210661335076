// Importing necessary dependencies and components for the Testimonials component
import { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Testimonial from "../../components/Admin/Testimonial";
import PageTransition from "../../components/PageTransition";

// Testimonials component definition
const Testimonials = () => {
  // State variables for managing component state
  const [selected, setSelected] = useState("modify"); // To track whether "Modify" or "Add" is selected
  const [data, setData] = useState([]); // To store the data retrieved from the API
  const [alert, setAlert] = useState("none"); // To manage alert messages
  const [loaded, setLoaded] = useState(false); // To track whether data has been loaded
  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };
  // useEffect to fetch data from the API when the component mounts
  useEffect(() => {
    // Async function to fetch data
    const fetchdata = async () => {
      try {
        // Making an HTTP GET request to the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/testimonials",
        );

        // Setting the retrieved data to the state
        setLoaded(true); // Indicating that data has been loaded
        setData(res.data);
      } catch (err) {
        // Handle error if the API request fails
        console.error(err);
      }
    };

    // Calling the fetchdata function
    fetchdata();

    // Cleanup function to clear the data when the component unmounts
    return () => {
      setData([]); // Clearing the data
    };
  }, [rerenderCount]); // Empty dependency array means this effect runs only once, similar to componentDidMount

  // JSX rendering based on whether data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          <AdminNavbar page={"testimonials"} />
          <div className="col mt-2 p-4">
            <div class="d-flex gap-4">
              {/* Button to select "Modify" */}
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("modify")}
              >
                Modify Testimonials
              </button>
              {/* Button to select "Add" */}
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("add")}
              >
                Add Testimonial
              </button>
            </div>
            <div className="mt-5">
              {selected === "modify" && (
                // Displaying the "Modify Testimonial" section
                <div>
                  <h2 className="fw-bold">Modify Testimonial</h2>
                  <div className="mt-4 ">
                    {/* Mapping through data to display individual testimonial items */}
                    {data?.map((dat, index) => {
                      return (
                        <Testimonial
                          key={index}
                          index={index}
                          data={dat}
                          type={"modify"}
                          onRerender={handleRerender}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {selected === "add" && (
              // Displaying the "Add Testimonial" section
              <Testimonial type={"add"} onRerender={handleRerender} />
            )}
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // If data is not loaded, render nothing
};

// Exporting the Testimonials component
export default Testimonials;
