// Importing necessary libraries and components (axios, useState, useEffect, icons, logo)
import axios from "axios";
import { useState, useEffect } from "react";
import logo from "../images/logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
// Functional component 'Footer'
const Footer = () => {
  // State variable 'data' to store contact information
  const [data, setData] = useState({});

  // useEffect hook to fetch contact information from the server
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Making a GET request to the specified API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/contactus",
        );

        // Updating the 'data' state with fetched contact information
        setData(res.data.contactUsFields);
      } catch (err) {
        // Handling errors if any occur during the data fetching process
        console.error(err);
      }
    };

    // Calling the fetchData function when the component mounts
    fetchData();

    // Cleanup function to reset 'data' state when the component unmounts
    return () => {
      setData({});
    };
  }, []);

  // Rendered component with footer content and dynamic data
  return (
    <div className="container-fluid p-5" style={{ backgroundColor: "#191970" }}>
      {/* Row with columns for logo and company description */}
      <div className="mt-3 row d-flex justify-content-around gap-md-6 gap-4">
        <div className="col-12 col-sm-5 col-lg-3 ">
          {/* Company logo */}
          <img src={logo} className="img-fluid rounded" />
          {/* Company description */}
          <div className="py-2 mt-3">
            <h6 className="footer-heading">We Help You Claim Better...</h6>
            <h6 className="footer-text">
              At Expert Claims Solutions, we are a team of dedicated insurance
              professionals with a passion for simplifying and enhancing the
              insurance claims experience.
            </h6>
          </div>
        </div>

        {/* Row with columns for social media links and quick links */}
        <div className="col-12 col-md-12 d-md-flex d-lg-block col-lg-4 order-1 order-sm-2 order-lg-1">
          <div className="row mb-5 mb-sm-4">
            {/* Heading for social media links */}
            <h3 className="footer-heading mb-4">Get In Touch</h3>
            <h6 className="footer-text">
              Click on the preferred platform to connect with us
            </h6>
            {/* Social media icons with links */}
            <div className="d-flex gap-3 mt-3 social-links">
              <a href="https://www.facebook.com/" target="_blank">
                <FacebookIcon fontSize="large" style={{ color: "#0866ff" }} />
              </a>
              <a href="https://www.instagram.com/" target="_blank">
                <InstagramIcon fontSize="large" style={{ color: "#E1306C" }} />
              </a>
              <a href="https://www.x.com/" target="_blank">
                <TwitterIcon fontSize="large" style={{ color: "#1DA1F2" }} />
              </a>
              <a href="https://www.youtube.com/" target="_blank">
                <YouTubeIcon fontSize="large" style={{ color: "#FF0000" }} />
              </a>
            </div>
          </div>

          {/* Quick links section */}
          <div className="row mb-4">
            {/* Heading for quick links */}
            <h3 className="footer-heading mb-4">Quick Links</h3>
            {/* Columns for individual quick link categories */}
            <div className="col ">
              {/* List of quick links */}
              <ul className="nav gap-3 flex-column footer-text ">
                <li className="nav-item ">
                  <a className="p-0  nav-link text-light" href="/aboutus">
                    About Us
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="p-0 nav-link text-light" href="/blogs">
                    Blogs
                  </a>
                </li>
                <li className="nav-item">
                  <a className="p-0 nav-link text-light" href="/services">
                    Services
                  </a>
                </li>
              </ul>
            </div>
            <div className="col">
              {/* List of quick links */}
              <ul className="nav gap-3 flex-column footer-text">
                <li className="nav-item">
                  <a className="p-0 nav-link text-light" href="/faqs">
                    FAQ'S
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="p-0 nav-link text-light" href="/contactus">
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="p-0 nav-link text-light" href="/careers">
                    Careers
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Column for contact information */}
        <div className="col-12 col-sm-6 col-lg-3 order-2 order-sm-1">
          {/* Heading for contact information */}
          <h3 className="footer-heading mb-4">Contact Us</h3>
          {/* Contact information */}
          <div className="footer-text">
            {/* Address with dangerously set HTML from 'data' prop */}
            <div className="d-flex">
              <HomeIcon />

              <div
                className="address ms-2 mb-3"
                dangerouslySetInnerHTML={{ __html: data?.address }}
              ></div>
            </div>
            {/* Email with a clickable mailto link */}
            <div className="d-flex mb-1 align-items-start">
              <EmailIcon />
              <a
                className="ms-2 footer-text"
                onClick={() =>
                  window.open(
                    `https://mail.google.com/mail/?view=cm&fs=1&to=${data?.email}`,
                    "_blank",
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <p>{data?.email}</p>
              </a>
            </div>

            {/* Mobile number */}
            <div className="d-flex align-items-start">
              <PhoneIcon />
              <p className="ms-2">{data?.mobile}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the Footer component as the default export
export default Footer;
