// Import React and necessary components
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadCrum from "../components/BreadCrum";
import PageTransition from "../components/PageTransition";
import Service from "../components/Service";
import axios from "axios";
import { useState, useEffect } from "react";
import Spinner from "../components/Spinner";

// Define the Services functional component
const Services = () => {
  // State to hold the fetched data
  const [data, setData] = useState([]);
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/services",
        );

        // Update state with the fetched data and set loaded to true
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData([]);
    };
  }, []);

  // Render the component
  return (
    <PageTransition>
      {/* Render the Header component with the "services" page */}
      <Header page={"services"} />
      {/* Render the BreadCrum component with "services" page and title "Services" */}
      <BreadCrum page={"services"} title={"Services"} />
      {loaded ? (
        // Render content when data is loaded
        <>
          <div className="container my-3 p-md-4 p-3">
            {/* Map through the data and render the Service component for each entry */}
            {data?.map((dat, index) => {
              return <Service data={dat} key={index} />;
            })}
          </div>
        </>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}
      {/* Render the Footer component */}
      <Footer />
    </PageTransition>
  );
};

// Export the Services component as the default export
export default Services;
