// Importing necessary dependencies from Framer Motion and React
import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";

// Functional component 'Reveal' with a 'children' prop
const Reveal = ({ children }) => {
  // Creating a reference to the DOM element
  const ref = useRef(null);

  // Using Framer Motion's useInView and useAnimation hooks
  const isInView = useInView(ref, { once: true }, { amount: "some" });
  const controls = useAnimation();

  // Effect hook to trigger animation when the element is in view
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  // Rendered component with motion.div applying animation variants
  return (
    <div ref={ref} style={{}}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 }, // Variant for hidden state
          visible: { opacity: 1, y: 0 }, // Variant for visible state
        }}
        initial="hidden" // Initial animation state
        animate={controls} // Animation controls
        transition={{ duration: 0.5, delay: 0.6 }} // Animation duration and delay
      >
        {children}
      </motion.div>
    </div>
  );
};

// Export the Reveal component as the default export
export default Reveal;
