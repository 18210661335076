// Importing necessary dependencies from React and Axios
import { useState, useEffect } from "react";
import axios from "axios";

// Importing components for the admin interface
import AdminNavbar from "../../components/Admin/AdminNavbar";
import Breadcrum from "../../components/Admin/Breadcrum";
import PageTransition from "../../components/PageTransition";

// Functional component 'BreadCrums'
const BreadCrums = () => {
  // State variables to manage the breadcrum data and loading status
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };
  // Effect hook to fetch breadcrum data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/breadcrums",
        );

        // Setting the modified data and marking it as loaded
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors if the data fetch fails
        console.error(err);
      }
    };

    // Calling the fetch data function
    fetchdata();

    // Cleanup function to reset data on component unmount
    return () => {
      setData([]);
    };
  }, [rerenderCount]);

  // Rendering the component content after data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin navigation bar component */}
          <AdminNavbar page={"breadcrums"} />
          <div className="col mt-2 p-4">
            <h2 className="mb-5 fw-bold">BreadCrums</h2>
            <div className="mt-4">
              {/* Mapping through the breadcrum data to render individual 'Breadcrum' components */}
              {data?.map((dat, index) => {
                return (
                  <Breadcrum
                    key={index}
                    data={dat}
                    index={index}
                    onRerender={handleRerender}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // Rendering null while data is being loaded
};

// Exporting the 'BreadCrums' component as the default export
export default BreadCrums;
