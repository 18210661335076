// Importing the logo and useNavigate hook from react-router-dom
import logo from "../images/logo.png";
import { useNavigate } from "react-router-dom";

// Functional component 'Header' with a 'page' prop
const Header = ({ page }) => {
  // Accessing the navigation function using useNavigate hook
  let navigate = useNavigate();

  // Rendered component containing the navigation bar and empty space for fixed positioning
  return (
    <>
      {/* Navigation bar with fixed positioning */}
      <nav className="navbar navbar-expand-xl fixed-top bg-white border-bottom border-bottom border-2">
        <div className="container-fluid p-xl-0 p-2">
          {/* Logo section with a link to the home page */}
          <div className="col-lg-4 ms-lg-3 col-md-10 col-10 ">
            <a href="/">
              {/* Company logo */}
              <img
                src={logo}
                alt="Expert Claims"
                className="logo-width img-fluid"
              />
            </a>
          </div>

          {/* Navbar toggle button for responsive design */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          {/* Navbar links and menu for different pages */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* List of navigation links with conditional styling based on the current 'page' */}
            <ul className="w-100 d-flex p-2 p-xl-0 gap-xl-4 navbar-nav  fw-semibold">
              <li className="nav-item">
                <button
                  className={`nav-link ${page === "home" ? "text-theme" : ""}`}
                  onClick={() => navigate("/")}
                >
                  Home
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    page === "aboutus" ? "text-theme" : ""
                  }`}
                  onClick={() => navigate("/aboutus")}
                >
                  About Us
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    page === "services" ? "text-theme" : ""
                  }`}
                  onClick={() => navigate("/services")}
                >
                  Services
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${page === "blogs" ? "text-theme" : ""}`}
                  onClick={() => navigate("/blogs")}
                >
                  Blogs
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${page === "faqs" ? "text-theme" : ""}`}
                  onClick={() => navigate("/faqs")}
                >
                  FAQ'S
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    page === "careers" ? "text-theme" : ""
                  }`}
                  onClick={() => navigate("/careers")}
                >
                  Careers
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    page === "contactus" ? "text-theme" : ""
                  }`}
                  onClick={() => navigate("/contactus")}
                >
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Empty space for fixed positioning, maintaining space below the navigation bar */}
      <div style={{ height: "100px", backgroundColor: "white" }}></div>
    </>
  );
};

// Export the Header component as the default export
export default Header;
