import { useState, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";

// Blog component to display and modify blog entries
const Blog = ({ data, index, type, onRerender }) => {
  return (
    <>
      {type === "modify" ? (
        // Display the modified blog entry using an accordion
        <div className="accordion-item mb-4 border shadow rounded">
          <h2 className="accordion-header">
            <button
              className="accordion-button p-4 collapsed faq-title"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
            >
              {data?.title}
            </button>
          </h2>
          <div id={`collapse${index}`} className="accordion-collapse collapse">
            <div className="accordion-body p-2 position-relative">
              {/* Display the form for modifying the blog entry */}
              <BlogForm type={type} data={data} onRerender={onRerender} />
            </div>
          </div>
        </div>
      ) : (
        // Display the form for adding a new blog entry
        <div className="container position-relative mt-4 border p-3 shadow">
          <div className="row ">
            <div className=" col-md-12">
              <h2 className=" fw-bold mb-5">Add Blog</h2>
              {/* Display the form for adding a new blog entry */}
              <BlogForm type={type} data={{}} onRerender={onRerender} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// BlogForm component to handle the form for adding or modifying a blog entry
const BlogForm = ({ data, type, onRerender }) => {
  const [alert, setAlert] = useState("none");
  const editor1 = useRef(null);
  const editor2 = useRef(null);
  const [formData, setFormData] = useState({
    ...data,
    image1: null,
    image2: null,
  });
  const [loading, setLoading] = useState(false);

  // Function to handle blog entry deletion
  const handleDelete = async () => {
    try {
      await axios.delete(
        process.env.REACT_APP_API_URL + `/api/blogs/${data?._id}`,
        { withCredentials: true },
      );

      onRerender();
    } catch (e) {
      console.error(e);
    }
  };

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const dataToSend = new FormData();
    if (formData.title) dataToSend.append("title", formData.title);
    if (formData.author) dataToSend.append("author", formData.author);
    if (formData.intro) dataToSend.append("intro", formData.intro);
    if (formData.videolink) dataToSend.append("videolink", formData.videolink);
    if (formData.content) dataToSend.append("content", formData.content);
    if (formData.image1) dataToSend.append("image1", formData.image1);
    if (formData.image2) dataToSend.append("image2", formData.image2);

    try {
      // Modify existing blog entry
      if (type === "modify") {
        await axios.put(
          process.env.REACT_APP_API_URL + `/api/blogs/${data?._id}`,
          dataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          },
        );
      }
      // Add new blog entry
      else if (type === "add") {
        await axios.post(
          process.env.REACT_APP_API_URL + "/api/blogs",
          dataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          },
        );
        setFormData({
          title: "",
          author: "",
          videolink: "",
          intro: "",
          content: "",
        });
      }

      setTimeout(() => {
        onRerender();
      }, 1000);

      // Set success alert
      setAlert("success");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Display error message in case of failure
      if (e.response.status === 400 || e.response.status === 401)
        window.alert(e.response.data.message);
      else console.error(e);
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Delete button for modifying entries */}
      <div className="position-absolute end-0 top-0 mt-2">
        {type === "modify" && (
          <button
            type="button"
            className=" btn btn-lg btn-danger"
            onClick={handleDelete}
          >
            Delete
          </button>
        )}
      </div>

      {/* Form fields for blog entry details */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Title:</label>
        <input
          className="form-control"
          type="text"
          name="title"
          value={formData?.title}
          onChange={handleInputChange}
          placeholder="Enter the title"
          required
        />
      </div>
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Author:</label>
        <input
          className="form-control"
          type="text"
          name="author"
          value={formData?.author}
          onChange={handleInputChange}
          placeholder="Enter the author name"
          required
        />
      </div>
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Video Link:</label>
        <input
          className="form-control"
          type="text"
          name="videolink"
          value={formData?.videolink}
          onChange={handleInputChange}
          placeholder="Paste the video link"
        />
      </div>
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">
          Introduction paragraph:
        </label>
        {/* JoditEditor for editing the introduction */}
        <JoditEditor
          ref={editor1}
          value={formData?.intro}
          tabIndex={1}
          onBlur={(newContent) =>
            setFormData({
              ...formData,
              intro: newContent,
            })
          }
        />
      </div>
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Content:</label>
        {/* JoditEditor for editing the content */}
        <JoditEditor
          ref={editor2}
          value={formData?.content}
          tabIndex={1}
          onBlur={(newContent) =>
            setFormData({
              ...formData,
              content: newContent,
            })
          }
        />
      </div>
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Image-1:</label>
        <div className="row">
          {formData?.image1 ? (
            // Display the chosen image
            <img
              src={URL.createObjectURL(formData?.image1)}
              className="col-md-3 img-fluid"
            />
          ) : (
            // Display the existing image for modification
            <img
              alt="no image"
              src={`${process.env.REACT_APP_API_URL}/${data?.image1}`}
              className="col-md-3 img-fluid"
            />
          )}
          <div className="col">
            {/* Input field for choosing image-1 */}
            <input
              className="form-control"
              type="file"
              name="image1"
              accept="image/*"
              onChange={handleInputChange}
              required={type === "add"}
            />
          </div>
        </div>
      </div>
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Image-2:</label>
        <div className="row">
          {formData?.image2 ? (
            // Display the chosen image
            <img
              src={URL.createObjectURL(formData?.image2)}
              className="col-md-3 img-fluid"
            />
          ) : (
            // Display the existing image for modification
            <img
              alt="no image"
              src={`${process.env.REACT_APP_API_URL}/${data?.image2}`}
              className="col-md-3 img-fluid"
            />
          )}
          <div className="col">
            {/* Input field for choosing image-2 */}
            <input
              className="form-control"
              type="file"
              name="image2"
              accept="image/*"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      {/* Alert messages for success or failure */}
      {alert === "success" && (
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Blog added/modified Successfully!
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlert("none")}
          ></button>
        </div>
      )}
      {alert === "failed" && (
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Unsuccessful please try again!
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlert("none")}
          ></button>
        </div>
      )}
      {/* Submit button for the form */}
      <div className="d-flex justify-content-center">
        <button className="admin-btn mt-4 " type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

// Export the Blog component
export default Blog;
