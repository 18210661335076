// Import necessary dependencies and components
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Blogs from "./pages/Blogs";
import SingleBlog from "./pages/SingleBlog";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import Faqs from "./pages/Faqs";
import ScrollToTop from "./components/ScrollToTop";
import AdminHome from "./pages/Admin/Home";
import AdminAboutUs from "./pages/Admin/AboutUs";
import AdminTestimonials from "./pages/Admin/Testimonials";
import AdminContactUs from "./pages/Admin/ContactUs";
import AdminServices from "./pages/Admin/Services";
import AdminFaqs from "./pages/Admin/Faqs";
import AdminCareers from "./pages/Admin/Careers";
import AdminBlogs from "./pages/Admin/Blogs";
import AdminBreadCrums from "./pages/Admin/BreadCrums";
import AdminLogin from "./pages/Admin/Login";
import { useState } from "react";
import "./globals.css";
import PageNotFound from "./pages/PageNotFound";
import AdminScrollings from "./pages/Admin/Scrollings";
import AdminLeaderships from "./pages/Admin/Leaderships";

// Define the main App component
function App() {
  // Retrieve login status from sessionStorage
  const loginStatus = sessionStorage.getItem("isLoggedIn");
  // State to manage login status
  const [login, setLogin] = useState(false);

  // Render the component
  return (
    <BrowserRouter>
      {/* Enable scroll restoration to top on route change */}
      <ScrollToTop />
      {/* Define application routes */}
      <Routes>
        {/* Default route for unknown paths */}
        <Route path="*" element={<PageNotFound />} />
        {/* Home route */}
        <Route path="/" element={<Home />} />
        {/* About Us route */}
        <Route path="/aboutus" element={<AboutUs />} />
        {/* Services route */}
        <Route path="/services" element={<Services />} />
        {/* Blogs route */}
        <Route path="/blogs" element={<Blogs />} />
        {/* Single Blog route */}
        <Route path="/blogs/:title" element={<SingleBlog />} />
        {/* Careers route */}
        <Route path="/careers" element={<Careers />} />
        {/* Contact Us route */}
        <Route path="/contactus" element={<ContactUs />} />
        {/* FAQ route */}
        <Route path="/faqs" element={<Faqs />} />
        {/* Admin Home route with login check */}
        <Route
          path="/admin/home"
          element={
            loginStatus === "true" ? <AdminHome /> : <Navigate to="/admin" />
          }
        />
        {/* Admin About Us route with login check */}
        <Route
          path="/admin/aboutus"
          element={
            loginStatus === "true" ? <AdminAboutUs /> : <Navigate to="/admin" />
          }
        />
        {/* Admin Testimonials route with login check */}
        <Route
          path="/admin/home/testimonials"
          element={
            loginStatus === "true" ? (
              <AdminTestimonials />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        {/* Admin Contact Us route with login check */}
        <Route
          path="/admin/contactus"
          element={
            loginStatus === "true" ? (
              <AdminContactUs />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        {/* Admin Services route with login check */}
        <Route
          path="/admin/services"
          element={
            loginStatus === "true" ? (
              <AdminServices />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        {/* Admin FAQs route with login check */}
        <Route
          path="/admin/faqs"
          element={
            loginStatus === "true" ? <AdminFaqs /> : <Navigate to="/admin" />
          }
        />
        {/* Admin Careers route with login check */}
        <Route
          path="/admin/careers"
          element={
            loginStatus === "true" ? <AdminCareers /> : <Navigate to="/admin" />
          }
        />
        {/* Admin Blogs route with login check */}
        <Route
          path="/admin/blogs"
          element={
            loginStatus === "true" ? <AdminBlogs /> : <Navigate to="/admin" />
          }
        />
        {/* Admin BreadCrumbs route with login check */}
        <Route
          path="/admin/breadcrums"
          element={
            loginStatus === "true" ? (
              <AdminBreadCrums />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        {/* Admin Scrollings route with login check */}
        <Route
          path="/admin/home/scrollings"
          element={
            loginStatus === "true" ? (
              <AdminScrollings />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        {/* Admin Leadership route with login check */}
        <Route
          path="/admin/aboutus/leadership"
          element={
            loginStatus === "true" ? (
              <AdminLeaderships />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        {/* Admin Login route */}
        <Route
          path="/admin"
          element={
            loginStatus === "true" ? (
              <Navigate to="/admin/home" />
            ) : (
              <AdminLogin setLogin={setLogin} />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

// Export the App component as the default export
export default App;
