// Importing necessary dependencies from React and Axios
import { useState, useEffect } from "react";
import axios from "axios";

// Importing components for the admin interface
import AdminNavbar from "../../components/Admin/AdminNavbar";
import PageTransition from "../../components/PageTransition";
import Leadership from "../../components/Admin/Leadership";

// Functional component 'Leaderships'
const Leaderships = () => {
  // State variables for selected component, data, and loading status
  const [selected, setSelected] = useState("modify");
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };
  // Effect hook to fetch leadership data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        // Making a GET request to fetch leadership data
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/leadership",
        );

        // Setting the fetched data and marking it as loaded
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors if the data fetch fails
        console.error(err);
      }
    };

    // Calling the fetch data function
    fetchdata();

    // Cleanup function to reset data on component unmount
    return () => {
      setData([]);
    };
  }, [rerenderCount]);

  // Rendering the component content after data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin navigation bar component */}
          <AdminNavbar page={"leadership"} />
          <div className="col mt-2 p-4">
            <div class="d-flex gap-4">
              {/* Buttons for selecting different components */}
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("modify")}
              >
                Modify Leadership
              </button>
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("add")}
              >
                Add Leadership
              </button>
            </div>
            <div className="mt-5">
              {/* Conditionally rendering content based on the selected component */}
              {selected === "modify" && (
                <div>
                  <h2 className=" fw-bold">Modify Leadership</h2>
                  <div className="mt-4 ">
                    {/* Mapping through leadership data and rendering Leadership component */}
                    {data?.map((dat, index) => {
                      return (
                        <Leadership
                          key={index}
                          index={index}
                          data={dat}
                          type={"modify"}
                          onRerender={handleRerender}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {selected === "add" && (
                <Leadership type={"add"} onRerender={handleRerender} />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // Rendering null while data is being loaded
};

// Exporting the 'Leaderships' component as the default export
export default Leaderships;
