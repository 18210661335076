import axios from "axios";
import { useState, useEffect } from "react";

// Testimonials component fetches testimonial data from the API and displays it in a carousel.
const Testimonials = ({ headings }) => {
  // State to store testimonial data
  const [data, setData] = useState([]);

  // Fetch testimonial data from the API on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/testimonials",
        );
        setData(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchdata();

    // Cleanup function to clear data on component unmount
    return () => {
      setData([]);
    };
  }, []);

  // Initialize the Bootstrap carousel on component mount
  useEffect(() => {
    function initializeCarousel() {
      const myCarousel = new window.bootstrap.Carousel(
        document.getElementById("carousel2"),
        {
          interval: 4000,
        },
      );
      myCarousel.cycle();
    }
    if (data.length > 0) initializeCarousel();
  }, [data]);

  // Render the Testimonials component with fetched testimonial data
  return data?.length > 0 ? (
    <div className="container text-center my-5 pb-3">
      <p className="heading">{headings?.heading}</p>
      <p className="subheading mb-4">{headings?.title}</p>

      {/* Bootstrap carousel for testimonials */}
      <div
        id="carousel2"
        className="bg-theme p-2 carousel slide position-relative"
        data-bs-ride="carousel"
      >
        {/* Carousel indicators */}
        <div className="carousel2 carousel-indicators">
          {data?.map((dat, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carousel2"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current="true"
              aria-label={"Slide" + (index + 1)}
            ></button>
          ))}
        </div>

        {/* Carousel items */}
        <div className="carousel-inner testimonial-height ">
          {data?.map((dat, index) => (
            <div
              key={index}
              className={`p-md-3 p-2 carousel-item ${index === 0 && "active"}`}
            >
              <div className="d-block w-100 ">
                {/* Testimonial content */}
                <div className="col-12 col-lg-10 mx-auto d-flex flex-column align-items-center">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${dat.image}`}
                    className="testimonial-image mb-4"
                  />
                  <div
                    className="testimonial-text mb-3"
                    dangerouslySetInnerHTML={{ __html: dat.content }}
                  ></div>
                  <h3 className="testimonial-name">{dat.name}</h3>
                  <h4 className="testimonial-location">{dat.location}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default Testimonials;
