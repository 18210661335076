// Importing necessary libraries and hooks (axios, useState)
import axios from "axios";
import { useState } from "react";

// Functional component 'ContactForm'
const ContactForm = () => {
  // State variables for form data, alert type, and loading state
  const [formData, setFormData] = useState({});
  const [alert, setAlert] = useState("none");
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Sending a POST request to the specified API endpoint with form data
      await axios.post(
        process.env.REACT_APP_API_URL + "/api/contactus/form",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // Resetting the form, setting a success alert, and resetting loading state
      e.target.reset();
      setAlert("success");
    } catch (e) {
      // Setting a failed alert in case of an error
      console.error(e);
      setAlert("failed");
    } finally {
      // Resetting loading state after submission
      setLoading(false);
    }
  };

  // Rendered component with form elements and dynamic content
  return (
    <div className="shadow rounded-2 p-4 col-12 col-md-9 col-lg-6 mx-auto mx-lg-0">
      {/* Form heading */}
      <h2 className="mb-5 contact-heading">Help us to Reach You..</h2>
      {/* Form with input fields for name, email, phone, and description */}
      <form onSubmit={handleSubmit}>
        {/* Name input field */}
        <div className="mb-3">
          <label className="form-label contact-field">Name*</label>
          <input
            className="form-input form-control"
            placeholder="Enter your name"
            onChange={(e) =>
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }
            required
          />
        </div>
        {/* Email input field */}
        <div className="mb-3">
          <label className="form-label contact-field fw-semibold">Email*</label>
          <input
            type="email"
            className="form-input form-control"
            placeholder="Enter your email"
            onChange={(e) =>
              setFormData({
                ...formData,
                email: e.target.value,
              })
            }
            required
          />
        </div>
        {/* Phone input field */}
        <div className="mb-3">
          <label className="form-label contact-field fw-semibold">
            Phone Number*
          </label>
          <input
            className="form-input form-control"
            placeholder="Enter your phone number"
            onChange={(e) =>
              setFormData({
                ...formData,
                phone: e.target.value,
              })
            }
            required
          />
        </div>
        {/* Description textarea field */}
        <div className="mb-3">
          <label className="form-label contact-field fw-semibold">
            Describe Your Case*
          </label>
          <textarea
            className="form-input form-control"
            rows="3"
            placeholder="Leave a brief comment on the help needed from us"
            onChange={(e) =>
              setFormData({
                ...formData,
                description: e.target.value,
              })
            }
            required
          ></textarea>
        </div>

        {/* Success alert message */}
        {alert === "success" && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            Thanks for reaching out! We will get back to you soon!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setAlert("none")}
            ></button>
          </div>
        )}

        {/* Failed alert message */}
        {alert === "failed" && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            Something went wrong! Please try again.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setAlert("none")}
            ></button>
          </div>
        )}

        {/* Submit button with dynamic content based on loading state */}
        <button
          type="submit"
          className="mt-3 form-btn p-2 w-100"
          disabled={loading}
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

// Export the ContactForm component as the default export
export default ContactForm;
