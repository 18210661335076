// Import necessary components and libraries
import Career from "../components/Career";
import axios from "axios";
import { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BreadCrum from "../components/BreadCrum";
import Reveal from "../components/Reveal";
import PageTransition from "../components/PageTransition";
import Spinner from "../components/Spinner";

// Define the Careers functional component
const Careers = () => {
  // State to hold the fetched data
  const [data, setData] = useState({});
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/careers",
        );

        // Update state with the fetched data and set loaded to true
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData({});
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // Render the component
  return (
    <PageTransition>
      <Header page={"careers"} />
      <BreadCrum page={"careers"} title={"Careers"} />

      {loaded ? (
        // Render content when data is loaded
        <Reveal>
          <div className="bg-theme py-4 py-md-5">
            <div className="container-md p-3">
              {/* Render career heading and introduction */}
              <p className="careers-heading">
                {data?.careerHeadings?.titleFields?.heading}
              </p>
              <p
                className="careers-intro mb-5"
                dangerouslySetInnerHTML={{
                  __html: data?.careerHeadings?.titleFields?.title,
                }}
              ></p>

              {/* Check if there are openings and render accordingly */}
              {data?.careers?.length > 0 ? (
                <div className="row justify-content-around row-gap-md-5 row-gap-4">
                  {data?.careers?.map((dat, index) => {
                    return <Career data={dat} key={index} />;
                  })}
                </div>
              ) : (
                // Display a message when there are no openings
                <div className="no-content subheading">
                  No Openings at this time
                </div>
              )}
            </div>
          </div>
        </Reveal>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}

      <Footer />
    </PageTransition>
  );
};

// Export the Careers component as the default export
export default Careers;
