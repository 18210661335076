// Import necessary components and libraries
import BreadCrum from "../components/BreadCrum";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Blog from "../components/Blog";
import axios from "axios";
import { useState, useEffect } from "react";
import PageTransition from "../components/PageTransition";
import Spinner from "../components/Spinner";

// Define the Blogs functional component
const Blogs = () => {
  // State to hold the fetched data
  const [data, setData] = useState([]);
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/blogs",
        );

        // Update state with the fetched data and set loaded to true
        setLoaded(true);
        setData(res.data);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData([]);
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // Render the component
  return (
    <PageTransition>
      <Header page={"blogs"} />
      <BreadCrum page={"blogs"} title={"Blogs"} />

      {loaded ? (
        // Render content when data is loaded
        <>
          {data?.length !== 0 ? (
            // Render the list of blogs when there are blogs to display
            <div className="container-md my-4">
              {data?.map((dat, index) => {
                return <Blog data={dat} key={index} />;
              })}
            </div>
          ) : (
            // Display a message when there are no blogs to display
            <div className="no-content subheading">No Blogs to Display</div>
          )}
        </>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}

      <Footer />
    </PageTransition>
  );
};

// Export the Blogs component as the default export
export default Blogs;
