import visionimg from "../images/visionimg.png";

// Vision component displays a vision section with an image and content.
const Vision = ({ data }) => {
  return (
    <div className="mt-md-4 mt-3 p-md-4 p-3 bg-theme">
      <div className="container-md">
        <div className="row justify-content-around align-items-center">
          {/* Image column */}
          <div className="p-md-4 p-3 col-12 col-md-8 col-lg-4">
            <img className="img-fluid" src={visionimg} alt="Vision" />
          </div>

          {/* Content column */}
          <div className="p-md-4 p-3 col-12 col-md-10 col-lg-7">
            <p className="heading">{data?.heading}</p>
            <p className="subheading mb-4">{data?.title}</p>
            {/* Render HTML content */}
            <div
              className="paragraph-text"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
