import { useState, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";

// Scrolling component for managing scrolling data
const Scrolling = ({ data, type, index, onRerender }) => {
  return (
    <>
      {type === "modify" ? (
        // Display scrolling data in accordion format for modification
        <div className="accordion-item mb-4 border shadow rounded">
          <h2 className="accordion-header">
            <button
              className="accordion-button p-4 collapsed faq-title"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></button>
          </h2>
          <div id={`collapse${index}`} className="accordion-collapse collapse">
            <div className="accordion-body p-2 position-relative">
              {/* Display ScrollingForm for modifying scrolling data */}
              <ScrollingForm type={type} data={data} onRerender={onRerender} />
            </div>
          </div>
        </div>
      ) : (
        // Display form for adding new scrolling data
        <div className="container position-relative mt-4 border p-3 shadow">
          <div className="row ">
            <div className=" col-md-12">
              <h2 className=" fw-bold mb-5">Add Scrolling</h2>
              {/* Display ScrollingForm for adding new scrolling data */}
              <ScrollingForm type={type} data={{}} onRerender={onRerender} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// ScrollingForm component for rendering the scrolling form
const ScrollingForm = ({ data, type, onRerender }) => {
  // State for managing alert messages
  const [alert, setAlert] = useState("none");
  // Ref for JoditEditor instance
  const editor = useRef(null);
  // State for managing form data
  const [formData, setFormData] = useState({ ...data, image: null });
  // State for managing loading state during form submission
  const [loading, setLoading] = useState(false);

  // Function to handle scrolling deletion
  const handleDelete = async () => {
    try {
      console.log("yes");
      // Send a DELETE request to delete the scrolling data
      await axios.delete(
        process.env.REACT_APP_API_URL + `/api/scrollings/${data?._id}`,
        {
          withCredentials: true,
        },
      );

      onRerender();
    } catch (e) {
      console.error(e);
    }
  };

  // Function to handle input changes in the form
  const handleInputChange = (e) => {
    const name = e.target.name;
    // Determine the input value based on the input type
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    // Update the form data with the new input value
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a FormData object to send as part of the request
    const dataToSend = new FormData();
    if (formData.content) dataToSend.append("content", formData.content);
    if (formData.image) dataToSend.append("image", formData.image);

    try {
      // Check the form type for modification or addition
      if (type === "modify") {
        // Send a PUT request to update the scrolling data
        await axios.put(
          process.env.REACT_APP_API_URL + `/api/scrollings/${data?._id}`,
          dataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          },
        );
      } else if (type === "add") {
        // Send a POST request to add new scrolling data
        await axios.post(
          process.env.REACT_APP_API_URL + "/api/scrollings",
          dataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          },
        );
        setFormData({
          content: "",
        });
      }

      setTimeout(() => {
        onRerender();
      }, 1000);

      // Set success alert
      setAlert("success");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      console.error(e);
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  // JSX for rendering the ScrollingForm component
  return (
    <form onSubmit={handleSubmit}>
      {/* Delete button for modifying scrolling data */}
      <div className="position-absolute end-0 top-0 mt-2">
        {type === "modify" && (
          <button
            type="button"
            className="btn btn-lg btn-danger"
            onClick={handleDelete}
          >
            Delete
          </button>
        )}
      </div>

      {/* Input field for scrolling content using JoditEditor */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Content:</label>
        <JoditEditor
          ref={editor}
          value={formData?.content}
          tabIndex={1}
          onBlur={(newContent) =>
            setFormData({
              ...formData,
              content: newContent,
            })
          }
        />
      </div>
      {/* Input field for scrolling image */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Image:</label>
        <div className="row">
          {/* Display the current image if available */}
          {formData?.image ? (
            <img
              src={URL.createObjectURL(formData?.image)}
              className="col-md-3 img-fluid"
            />
          ) : (
            <img
              alt="no image"
              src={`${process.env.REACT_APP_API_URL}/${data?.image}`}
              className="col-md-3 img-fluid"
            />
          )}
          {/* Input field for selecting a new image */}
          <div className="col">
            <input
              className="form-control"
              type="file"
              name="image"
              accept="image/*"
              onChange={handleInputChange}
              required={type === "add"}
            />
          </div>
        </div>
      </div>
      {/* Display success message upon successful form submission */}
      {alert === "success" && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Scrolling added/modified Successfully!
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlert("none")}
          ></button>
        </div>
      )}
      {/* Display error message if form submission fails */}
      {alert === "failed" && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Unsuccessful please try again!
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlert("none")}
          ></button>
        </div>
      )}
      {/* Button for submitting the form */}
      <div className="d-flex justify-content-center">
        <button className="admin-btn mt-4 " type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

// Export the Scrolling component as the default export
export default Scrolling;
