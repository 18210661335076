// React component 'Spinner' to display a loading spinner
const Spinner = () => {
  // Rendered component with a centered loading spinner
  return (
    <div className="d-flex justify-content-center min-vh-100">
      <div
        className="spinner-border text-primary mt-5"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      ></div>
    </div>
  );
};

// Export the Spinner component as the default export
export default Spinner;
