// Importing necessary dependencies from React and Axios
import { useState, useEffect, useRef } from "react";
import axios from "axios";

// Importing components for the admin interface
import AdminNavbar from "../../components/Admin/AdminNavbar";
import JoditEditor from "jodit-react";
import PageTransition from "../../components/PageTransition";

// Functional component 'ContactUs'
const ContactUs = () => {
  // Ref for the Jodit editor, state variables for alert, form data, contact data, loading status, and data loading status
  const editor = useRef(null);
  const [alert, setAlert] = useState("none");
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // Effect hook to fetch contact data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/contactus",
        );

        // Setting the fetched data and marking it as loaded
        setLoaded(true);
        setData(res.data?.contactUsFields);
      } catch (err) {
        // Handle errors if the data fetch fails
        console.error(err);
      }
    };

    // Calling the fetch data function
    fetchdata();

    // Cleanup function to reset data on component unmount
    return () => {
      setData([]);
    };
  }, []);

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Making a PUT request to modify contact data
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/contactus`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      // Setting success alert and resetting after a timeout
      setAlert("success");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Setting failed alert and resetting after a timeout
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  // Rendering the component content after data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin navigation bar component */}
          <AdminNavbar page={"contactus"} />
          <div className="col mt-2 p-4">
            <div className="container mt-4 border p-3 shadow">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit}>
                    <h2 className="mb-5 fw-bold">Contact Us</h2>

                    {/* Form input for heading */}
                    <div className="form-group m-4">
                      <label className="fw-semibold fs-5 form-label">
                        Heading
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data?.heading}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            heading: e.target.value,
                          })
                        }
                      />
                    </div>

                    {/* Jodit editor for the 'address' field */}
                    <div className="form-group m-4">
                      <label className="fw-semibold fs-5 form-label">
                        Address:
                      </label>
                      <JoditEditor
                        ref={editor}
                        value={data?.address}
                        tabIndex={1}
                        onBlur={(newContent) =>
                          setFormData({
                            ...formData,
                            address: newContent,
                          })
                        }
                      />
                    </div>

                    {/* Form input for email */}
                    <div className="form-group m-4">
                      <label className="fw-semibold fs-5 form-label">
                        E-mail:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data?.email}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>

                    {/* Form input for phone number */}
                    <div className="form-group m-4">
                      <label className="fw-semibold fs-5 form-label">
                        Phone Number:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={data?.mobile}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            mobile: e.target.value,
                          })
                        }
                      />
                    </div>

                    {/* Alert messages based on submission status */}
                    {alert === "success" && (
                      <div
                        class="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        Successfully made the changes!
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setAlert("none")}
                        ></button>
                      </div>
                    )}
                    {alert === "failed" && (
                      <div
                        class="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        Unsuccessful please try again!
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setAlert("none")}
                        ></button>
                      </div>
                    )}

                    {/* Submit button with loading state */}
                    <div className="d-flex justify-content-center">
                      <button
                        className="admin-btn m-4"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // Rendering null while data is being loaded
};

// Exporting the 'ContactUs' component as the default export
export default ContactUs;
