// Importing necessary hooks and libraries (useState, useEffect, axios)
import { useState, useEffect } from "react";
import axios from "axios";

// Functional component 'BreadCrum' that takes 'page' and 'title' props
const BreadCrum = ({ page, title }) => {
  // State variable 'data' to store breadcrumb information
  const [data, setData] = useState({});

  // useEffect hook to fetch breadcrumb data from the server
  useEffect(() => {
    // Async function to fetch data using axios
    const fetchData = async () => {
      try {
        // Making a GET request to the specified API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + `/api/breadcrums/${page}`,
        );
        // Updating the 'data' state with fetched breadcrumb fields
        setData(res.data.breadCrumFields);
      } catch (err) {
        // Handling errors if any occur during the data fetching process
        console.error(err);
      }
    };

    // Calling the fetchData function when the component mounts
    fetchData();

    // Cleanup function to reset 'data' state when the component unmounts
    return () => {
      setData({});
    };
  }, [page]); // Dependency array ensures useEffect runs only when 'page' prop changes

  // Rendered component with dynamic styles based on fetched data
  return (
    <div
      className="breadcrum-container"
      style={{
        // Background image sourced dynamically from fetched data
        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${data?.image})`,
      }}
    >
      {/* Container overlay with text content */}
      <div className="container-overlay d-flex align-items-center justify-content-start p-3 p-md-5">
        {/* Breadcrumb heading with dynamic content from 'title' prop */}
        <p className="breadcrum-heading text-light">{title}</p>
      </div>
    </div>
  );
};

// Export the BreadCrum component as the default export
export default BreadCrum;
