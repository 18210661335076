import { useState } from "react";
import axios from "axios";

// Breadcrum component to display and modify breadcrum entries
const Breadcrum = ({ data, index, onRerender }) => {
  // State variables for handling alerts, form data, and loading state
  const [alert, setAlert] = useState("none");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  // Function to handle input changes in the form
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.files[0];

    // Update formData with the new input value
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e, component) => {
    e.preventDefault();
    setLoading(true);
    const dataToSend = new FormData();

    // Append image data to FormData if available
    if (formData.image) dataToSend.append("image", formData.image);

    try {
      // Send a PUT request to update breadcrum data
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/breadcrums/${component}`,
        dataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        },
      );

      setTimeout(() => {
        onRerender();
      }, 1000);

      // Set success alert
      setAlert("success");
    } catch (e) {
      // Display failure alert in case of an error
      setAlert("failed");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="accordion-item mb-4 border shadow rounded">
      <h2 className="accordion-header">
        <button
          className="accordion-button p-4 collapsed faq-title"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${index}`}
          aria-expanded="false"
          aria-controls={`collapse${index}`}
        >
          {data?.component.toUpperCase()}
        </button>
      </h2>
      <div id={`collapse${index}`} className="accordion-collapse collapse">
        <div className="accordion-body p-2 position-relative">
          {/* Breadcrum modification form */}
          <form onSubmit={(e) => handleSubmit(e, data?.component)}>
            <div className="form-group m-3">
              <label className="fw-semibold fs-5 form-label">Image:</label>
              <div className="row">
                {/* Display current or chosen image */}
                {formData?.image ? (
                  <img
                    src={URL.createObjectURL(formData?.image)}
                    className="col-md-3 img-fluid"
                  />
                ) : (
                  <img
                    alt="no image"
                    src={`${process.env.REACT_APP_API_URL}/${data?.breadCrumFields?.image}`}
                    className="col-md-3 img-fluid"
                  />
                )}
                <div className="col">
                  {/* Input field for choosing a new image */}
                  <input
                    className="form-control"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            {/* Success and failure alerts */}
            {alert === "success" && (
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                Breadcrum modified Successfully!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {alert === "failed" && (
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {/* Submit button for the form */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4 "
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Export the Breadcrum component
export default Breadcrum;
