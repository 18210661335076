import { useState, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";

// VisionMission component for managing vision/mission data
const VisionMission = ({ data, component, onRerender }) => {
  // Ref for JoditEditor instance
  const editor = useRef(null);
  // State for managing alert messages
  const [alert, setAlert] = useState("none");
  // State for managing form data
  const [formData, setFormData] = useState({});
  // State for managing loading state during form submission
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Send a PUT request to update vision/mission data
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/aboutus/${component}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      // Set success alert and clear after 3 seconds
      setAlert("success");
      onRerender();
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Set failed alert and clear after 3 seconds
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // JSX for rendering the VisionMission component
  return (
    <div className="container mt-4 border p-3 shadow">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <h2 className="mb-5 fw-bold">About Us / {data?.heading}</h2>

            {/* Input field for vision/mission heading */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Heading:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.heading}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heading: e.target.value,
                  })
                }
              />
            </div>

            {/* Input field for vision/mission title */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Title:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.title}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  })
                }
              />
            </div>

            {/* Input field for vision/mission content using JoditEditor */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Content:</label>
              <JoditEditor
                ref={editor}
                value={data?.content}
                tabIndex={1}
                onBlur={(newContent) =>
                  setFormData({
                    ...formData,
                    content: newContent,
                  })
                }
                onChange={(newContent) => {}}
              />
            </div>

            {/* Display success message if form submission is successful */}
            {alert === "success" && (
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {component} modified Successfully!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}

            {/* Display error message if form submission fails */}
            {alert === "failed" && (
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}

            {/* Button for submitting the form */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4 "
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Export the VisionMission component as the default export
export default VisionMission;
