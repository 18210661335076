import { useState } from "react";
import axios from "axios";

// LandingPage component for managing the landing page content
const LandingPage = ({ data, onRerender }) => {
  // State for managing alert messages
  const [alert, setAlert] = useState("none");
  // State for managing form data
  const [formData, setFormData] = useState({});
  // State for managing loading state during form submission
  const [loading, setLoading] = useState(false);

  // Function to handle input changes in the form
  const handleInputChange = (e) => {
    const name = e.target.name;
    // Determine the input value based on the input type
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    // Update the form data with the new input value
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a FormData object to send as part of the request
    const dataToSend = new FormData();
    if (formData.title) dataToSend.append("title", formData.title);
    if (formData.subtitle) dataToSend.append("subtitle", formData.subtitle);
    if (formData.image) dataToSend.append("image", formData.image);

    try {
      // Send a PUT request to update the landing page content
      const res = await axios.put(
        process.env.REACT_APP_API_URL + "/api/home/landingpage",
        dataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        },
      );

      // Check if the request was successful
      if (res.status !== 200) throw new Error("You are not authorized");

      // Set success alert and hide it after 3 seconds
      setAlert("success");
      onRerender();
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Set failed alert and hide it after 3 seconds
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // JSX for rendering the LandingPage component
  return (
    <div className="container mt-4 border p-3 shadow">
      <div className="row">
        <div className="col-md-12">
          {/* Form for updating landing page content */}
          <form onSubmit={handleSubmit}>
            <h2 className="mb-5 fw-bold">Home / Landing Page</h2>
            {/* Input field for the landing page title */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Title:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.title}
                onChange={handleInputChange}
              />
            </div>
            {/* Input field for the landing page subtitle */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Subtitle:</label>
              <input
                className="form-control"
                type="text"
                name="subtitle"
                defaultValue={data?.subtitle}
                onChange={handleInputChange}
              />
            </div>
            {/* Input field for the landing page image */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Image:</label>
              <div className="row">
                {/* Display the current image if available */}
                {formData?.image ? (
                  <img
                    src={URL.createObjectURL(formData?.image)}
                    className="col-md-3 img-fluid"
                  />
                ) : (
                  <img
                    alt="no image"
                    src={`${process.env.REACT_APP_API_URL}/${data?.image}`}
                    className="col-md-3 img-fluid"
                  />
                )}
                {/* Input field for selecting a new image */}
                <div className="col">
                  <input
                    className="form-control"
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            {/* Display success alert upon successful submission */}
            {alert === "success" && (
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                Landing page modified Successfully!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {/* Display failed alert if submission fails */}
            {alert === "failed" && (
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {/* Submit button */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4 "
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Export the LandingPage component as the default export
export default LandingPage;
