// Functional component 'Career' that takes a 'data' prop
const Career = ({ data }) => {
  // Function to format a date string
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Extracting day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Formatting the date as DD/MM/YYYY
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;

    return formattedDate;
  }

  // Rendered component with dynamic content from 'data' prop
  return (
    <div className="col-12 col-sm-6 col-xl-4 d-flex justify-content-center">
      {/* Container with white background and rounded corners */}
      <div className="career-width bg-white rounded-3">
        {/* Primary theme-colored bar at the top */}
        <div className="bg-theme__primary p-1 rounded-3"></div>
        <div className="my-2 px-3 ">
          {/* Location of the career position */}
          <p className="career-location">{data?.location}</p>
          {/* Title of the career position */}
          <p className="career-title">{data?.title}</p>

          {/* Experience section with dynamic content from 'data' prop */}
          <div className="d-flex">
            <p className="career-exp me-2 ">Experience&nbsp;: </p>
            <p className="career-ans"> {data?.experience}</p>
          </div>

          {/* Skills section with dynamic content from 'data' prop */}
          <div className="d-flex">
            <p className="career-exp me-2">Skills&nbsp;: </p>
            <p className="career-ans"> {data?.skills}</p>
          </div>

          {/* Date the job was posted */}
          <p className="career-posted">
            Job Posted on {formatDate(data?.createdAt)}
          </p>

          {/* Button to apply for the career position */}
          <div className="mt-3 d-flex justify-content-center">
            <button
              className="apply-btn"
              onClick={() => window.open(data?.link, "_blank")}
            >
              Apply now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the Career component as the default export
export default Career;
