const PageNotFound = () => {
  return (
    <div className="min-vh-100 bg-theme d-flex flex-column justify-content-center align-items-center p-4">
      <h1 className="subheading mb-3">404</h1>
      <h1 className="subheading mb-3">Page Not Found</h1>
      <p className="heading">The page you are looking for does not exist.</p>
    </div>
  );
};

export default PageNotFound;
