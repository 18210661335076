// Functional component 'Mission' with a 'data' prop
const Mission = ({ data }) => {
  // Rendered component containing mission details
  return (
    <div className="container-md mt-md-4 mt-3 p-md-4 p-3">
      <div className="row">
        {/* Column for content with a maximum width */}
        <div className="col-12 col-md-10 col-lg-9 mx-auto">
          {/* Main heading for the mission section */}
          <p className="heading">{data?.heading}</p>
          {/* Subheading for the mission section */}
          <p className="subheading mb-4">{data?.title}</p>
          {/* Paragraph text for the mission content with HTML rendering */}
          <div
            className="paragraph-text"
            dangerouslySetInnerHTML={{ __html: data?.content }}
          ></div>
        </div>
      </div>
    </div>
  );
};

// Export the Mission component as the default export
export default Mission;
