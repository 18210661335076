// Importing necessary dependencies from React and React Router DOM
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Functional component 'ScrollToTop'
const ScrollToTop = () => {
  // Accessing the current location using React Router DOM's useLocation hook
  const { pathname } = useLocation();

  // Effect hook to scroll to the top of the window when the pathname changes
  useEffect(() => {
    window.scrollTo({
      top: 0, // Scrolling to the top of the window
      left: 0,
      behavior: "instant", // Instant scrolling behavior
    });
  }, [pathname]);

  // The component returns null, as it doesn't render any visible content
  return null;
};

// Export the ScrollToTop component as the default export
export default ScrollToTop;
