import { useState } from "react";
import axios from "axios";

// Career component for managing career entries (add or modify)
const Career = ({ data, type, index, onRerender }) => {
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Month is zero-based
    let day = today.getDate();

    // Add leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${day}-${month}-${year}`;
  }
  return (
    <>
      {/* Render modification or addition based on the type */}
      {type === "modify" ? (
        <div className="accordion-item border mb-4 shadow rounded">
          {/* Accordion header for a modified career entry */}
          <h2 className="accordion-header">
            <button
              className="accordion-button p-4 collapsed faq-title"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
            >
              {data?.title}
            </button>
          </h2>
          {/* Accordion body containing the career form for modification */}
          <div id={`collapse${index}`} className="accordion-collapse collapse">
            <div className="accordion-body p-2 position-relative">
              <CareerForm type={type} data={data} onRerender={onRerender} />
            </div>
          </div>
        </div>
      ) : (
        /* Container for adding a new career entry */
        <div className="container position-relative mt-4 border p-3 shadow">
          <div className="row">
            <div className="col-md-12">
              <h2 className="fw-bold mb-5">Add Career</h2>
              {/* Career form for adding a new entry */}
              <CareerForm
                type={type}
                data={{ date: getTodayDate() }}
                getTodayDate={getTodayDate}
                onRerender={onRerender}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// CareerForm component for rendering and handling the career form
const CareerForm = ({ data, type, onRerender, getTodayDate }) => {
  // State for managing alert messages
  const [alert, setAlert] = useState("none");
  // State for managing form data
  const [formData, setFormData] = useState(data);
  // State for managing loading state during form submission
  const [loading, setLoading] = useState(false);

  function convertDateFormat(inputDate, original) {
    if (inputDate === null) return null;

    const myArray = inputDate.split("-");

    let year, month, day;

    if (original) {
      year = myArray[0];
      month = myArray[1];
      day = myArray[2];
    } else {
      year = myArray[2];
      month = myArray[1];
      day = myArray[0];
    }

    let formattedDate = original
      ? day + "-" + month + "-" + year
      : year + "-" + month + "-" + day;

    return formattedDate;
  }

  // Function to handle deletion of a career entry
  const handleDelete = async () => {
    try {
      // Send a DELETE request to the server to delete the career entry
      await axios.delete(
        process.env.REACT_APP_API_URL + `/api/careers/${data?._id}`,
        {
          withCredentials: true,
        },
      );

      onRerender();
    } catch (e) {
      console.error(e);
    }
  };

  // Function to handle form submission (add or modify)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Send a request to the server based on the type (add or modify)
      if (type === "modify") {
        // If modifying, send a PUT request with the updated data
        await axios.put(
          process.env.REACT_APP_API_URL + `/api/careers/${data?._id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
        );
      } else if (type === "add") {
        // If adding, send a POST request with the new data
        await axios.post(
          process.env.REACT_APP_API_URL + "/api/careers",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
        );
        setFormData({
          title: "",
          experience: "",
          skills: "",
          location: "",
          link: "",
          date: getTodayDate(),
        });
      }

      setTimeout(() => {
        onRerender();
      }, 1000);

      // Set success alert
      setAlert("success");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Set failed alert if there is an error
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // JSX for rendering the career form
  return (
    <form onSubmit={handleSubmit}>
      <div className="position-absolute end-0 top-0 mt-2">
        {/* Render delete button only in modification mode */}
        {type === "modify" && (
          <button
            type="button"
            className="btn btn-lg btn-danger"
            onClick={handleDelete}
          >
            Delete
          </button>
        )}
      </div>

      {/* Input field for career title */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Title:</label>
        <input
          className="form-control"
          type="text"
          value={formData?.title}
          onChange={(e) =>
            setFormData({
              ...formData,
              title: e.target.value,
            })
          }
          placeholder="Enter the title"
          required
        />
      </div>
      {/* Input field for experience */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Experience:</label>
        <input
          className="form-control"
          type="text"
          value={formData?.experience}
          onChange={(e) =>
            setFormData({
              ...formData,
              experience: e.target.value,
            })
          }
          placeholder="Enter the experience"
          required
        />
      </div>
      {/* Input field for skills */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Skills:</label>
        <input
          className="form-control"
          type="text"
          value={formData?.skills}
          onChange={(e) =>
            setFormData({
              ...formData,
              skills: e.target.value,
            })
          }
          placeholder="Enter the skills"
          required
        />
      </div>
      {/* Input field for location */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Location:</label>
        <input
          className="form-control"
          type="text"
          value={formData?.location}
          onChange={(e) =>
            setFormData({
              ...formData,
              location: e.target.value,
            })
          }
          placeholder="Enter the location"
          required
        />
      </div>
      {/* Input field for form link */}
      <div className="form-group m-4">
        <label className="fw-semibold fs-5 form-label">Form Link:</label>
        <input
          className="form-control"
          type="text"
          value={formData?.link}
          onChange={(e) =>
            setFormData({
              ...formData,
              link: e.target.value,
            })
          }
          placeholder="Paste the form link"
          required
        />
      </div>

      {/* Input field for career date */}
      <div className="col-4 form-group m-4">
        <label className="fw-semibold fs-5 form-label">Date:</label>
        <input
          className="form-control"
          type="date"
          value={convertDateFormat(formData?.date, false)}
          onChange={(e) =>
            setFormData({
              ...formData,
              date: convertDateFormat(e.target.value, true),
            })
          }
          required
        />
      </div>

      {/* Render success alert upon successful submission */}
      {alert === "success" && (
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Career added/modified Successfully!
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlert("none")}
          ></button>
        </div>
      )}
      {/* Render failed alert if submission fails */}
      {alert === "failed" && (
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Unsuccessful please try again!
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setAlert("none")}
          ></button>
        </div>
      )}
      {/* Submit button */}
      <div className="d-flex justify-content-center">
        <button className="admin-btn mt-4 " type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

// Export the Career component as the default export
export default Career;
