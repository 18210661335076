// Functional component 'LandingPage' with a 'data' prop
const LandingPage = ({ data }) => {
  // Rendered component with a background image and overlay
  return (
    <div
      className="cover-container"
      style={{
        // Setting the background image dynamically using data from the prop
        backgroundImage: `url(${process.env.REACT_APP_API_URL}/${data?.image})`,
      }}
    >
      {/* Overlay container for text content */}
      <div className="container-overlay">
        {/* Container for aligning content vertically in the center */}
        <div className="container h-100 p-3 p-md-4 d-flex flex-column justify-content-center">
          {/* Column for text content with a maximum width */}
          <div className="col-12 col-md-10 col-lg-8">
            {/* Main heading */}
            <h1 className="mt-md-5 landing-heading mb-4">{data?.title}</h1>
            {/* Subheading */}
            <h2 className="landing-subheading mb-4">{data?.subtitle}</h2>
            {/* Buttons for actions */}
            <div className="d-flex  gap-3">
              {/* Button to navigate to the Contact Us page */}
              <a href="/contactus" className="registerbtn">
                Contact Us
              </a>
              {/* Button to navigate to the About Us page */}
              <a href="/aboutus" className="aboutbtn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the LandingPage component as the default export
export default LandingPage;
