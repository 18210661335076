// Importing necessary dependencies from React and Axios
import { useState, useEffect } from "react";
import axios from "axios";

// Importing components for the admin interface
import LandingPage from "../../components/Admin/LandingPage";
import WhyChooseUs from "../../components/Admin/WhyChooseUs";
import About from "../../components/Admin/About";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import PageTransition from "../../components/PageTransition";

// Functional component 'Home'
const Home = () => {
  // State variables for data, selected component, and data loading status
  const [data, setData] = useState({});
  const [selected, setSelected] = useState("landingpage");
  const [loaded, setLoaded] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };
  // Effect hook to fetch home data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        // Making a GET request to fetch home data
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/home",
        );

        // Modifying the response data structure
        const modifiedres = {};
        res.data?.forEach((dat) => {
          const key = dat.component;
          modifiedres[key] = { ...dat };
        });

        // Setting the modified data and marking it as loaded
        setLoaded(true);
        setData(modifiedres);
      } catch (err) {
        // Handle errors if the data fetch fails
        console.error(err);
      }
    };

    // Calling the fetch data function
    fetchdata();

    // Cleanup function to reset data on component unmount
    return () => {
      setData({});
    };
  }, [rerenderCount]);

  // Rendering the component content after data is loaded
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin navigation bar component */}
          <AdminNavbar page={"home"} />
          <div className="col mt-2 p-4">
            <div className="d-flex gap-4">
              {/* Buttons for selecting different components */}
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("landingpage")}
              >
                Landing Page
              </button>
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("whychooseus")}
              >
                {data?.whychooseus?.whyChooseUsFields?.heading}
              </button>
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("about")}
              >
                {data?.aboutcompany?.aboutCompanyFields?.heading}
              </button>
              <button
                type="button"
                className="admin-btn"
                onClick={() => setSelected("testimonials")}
              >
                {data?.testimonials?.titleFields?.heading}
              </button>
            </div>
            <div className="mt-5">
              {/* Conditionally rendering content based on the selected component */}
              {selected === "landingpage" && (
                <LandingPage
                  data={data?.landingpage?.landingPageFields}
                  onRerender={handleRerender}
                />
              )}
              {selected === "whychooseus" && (
                <WhyChooseUs
                  data={data?.whychooseus?.whyChooseUsFields}
                  onRerender={handleRerender}
                />
              )}
              {selected === "about" && (
                <About
                  data={data?.aboutcompany?.aboutCompanyFields}
                  page={"home"}
                  onRerender={handleRerender}
                />
              )}
              {selected === "testimonials" && (
                <Testimonials
                  data={data?.testimonials?.titleFields}
                  onRerender={handleRerender}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null; // Rendering null while data is being loaded
};

// Functional component 'Testimonials'
const Testimonials = ({ data, onRerender }) => {
  // State variables for alert type, form data, loading status
  const [alert, setAlert] = useState("none");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Making a PUT request to update testimonial data
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/home/testimonials`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      // Setting success alert and resetting after 3 seconds
      setAlert("success");
      onRerender();
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Setting failed alert and resetting after 3 seconds
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      console.error(e);
    } finally {
      // Resetting loading status
      setLoading(false);
    }
  };

  // Rendering the component content
  return (
    <div className="container mt-4 border p-3 shadow">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <h2 className="mb-5 fw-bold">Home / {data?.heading}</h2>

            {/* Input field for heading */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Heading:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.heading}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heading: e.target.value,
                  })
                }
              />
            </div>

            {/* Input field for title */}
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Title:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.title}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  })
                }
              />
            </div>

            {/* Success and failure alerts */}
            {alert === "success" && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                Testimonial Headings modified Successfully!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {alert === "failed" && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}

            {/* Submit button */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4 "
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Exporting the 'Home' component as the default export
export default Home;
