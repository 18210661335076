// Importing the 'Reveal' component from the specified file path
import Reveal from "../components/Reveal";

// Functional component 'Service' with a 'data' prop
const Service = ({ data }) => {
  // Rendered component with 'Reveal' component wrapping the content
  return (
    <Reveal>
      <div className="row justify-content-center align-items-center">
        {/* Left column containing an image */}
        <div className="col-lg-6 col-md-10 mb-3 px-lg-4 mt-md-3 mt-2">
          <img
            src={`${process.env.REACT_APP_API_URL}/${data?.image}`}
            className="img-fluid"
          />
        </div>
        {/* Right column containing service title and content */}
        <div className="px-lg-3 col-lg-6 col-md-10 mb-md-3 mb-2">
          <p className="services-heading">{data?.title}</p>
          {/* Service content with HTML rendering */}
          <div
            className="services-content "
            dangerouslySetInnerHTML={{ __html: data?.content }}
          ></div>
        </div>
        {/* Horizontal line separator */}
        <hr className="border border-1 opacity-75 my-3" />
      </div>
    </Reveal>
  );
};

// Export the Service component as the default export
export default Service;
