// Importing necessary dependencies from React and React Router
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Importing a component for page transition effect
import PageTransition from "../../components/PageTransition";

// Functional component 'Login' with props 'setLogin'
const Login = ({ setLogin }) => {
  // State variables for form data, navigation, and alert messages
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [alert, setAlert] = useState("none");
  const [alertMessage, setAlertMessage] = useState();

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Making a POST request to the login API endpoint
      await axios.post(
        process.env.REACT_APP_API_URL + "/api/auth/login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      // Setting session storage for user login status
      sessionStorage.setItem("isLoggedIn", true);

      // Automatically logging out after 3 hours (10,800,000 milliseconds)
      setTimeout(() => {
        setLogin(false);
        sessionStorage.setItem("isLoggedIn", false);
        window.location.reload(false);
      }, 10800000);

      // Setting the login state to true and navigating to the admin home page
      setLogin(true);
      navigate("/admin/home");
    } catch (err) {
      // Handling errors in case of a failed login attempt
      setAlert("failed");
      setAlertMessage(err?.response?.data);
      console.error(err);
    }
  };

  // Rendering the login form with a page transition effect
  return (
    <PageTransition>
      <div className="min-vh-100 d-flex justify-content-center align-items-center bg-theme__primary">
        <div className="col-md-4 p-4 bg-white rounded-4 border shadow">
          <h2 className="fw-bold text-center mb-5">Login</h2>
          <form onSubmit={handleSubmit}>
            {/* Form input for username */}
            <div className="form-group m-3">
              <label className="fw-semibold form-label">Username:</label>
              <input
                className="form-control"
                type="text"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    username: e.target.value,
                  })
                }
                placeholder="Enter the username"
                required
              />
            </div>
            {/* Form input for password */}
            <div className="form-group m-3">
              <label className="fw-semibold form-label">Password:</label>
              <input
                className="form-control"
                type="password"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  })
                }
                placeholder="Enter the password"
                required
              />
            </div>
            {/* Alert for displaying login failure messages */}
            {alert === "failed" && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                {alertMessage}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {/* Button for submitting the login form */}
            <div className="d-flex justify-content-center">
              <button className="admin-btn mt-4 " type="submit">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </PageTransition>
  );
};

// Exporting the 'Login' component as the default export
export default Login;
