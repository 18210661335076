// Import necessary components and libraries
import About from "../components/About";
import BreadCrum from "../components/BreadCrum";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Leadership from "../components/Leadership";
import Mission from "../components/Mission";
import PageTransition from "../components/PageTransition";
import Reveal from "../components/Reveal";
import Spinner from "../components/Spinner";
import Vision from "../components/Vision";
import axios from "axios";
import { useState, useEffect } from "react";

// Define the AboutUs functional component
const AboutUs = () => {
  // State to hold the fetched data
  const [data, setData] = useState({});
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/aboutus",
        );

        // Organize the fetched data into a modified structure
        const modifiedRes = {};
        res.data?.forEach((dat) => {
          const key = dat.component;
          modifiedRes[key] = { ...dat };
        });

        // Update state with the modified data and set loaded to true
        setLoaded(true);
        setData(modifiedRes);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData({});
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  // Render the component
  return (
    <PageTransition>
      <Header page={"aboutus"} />
      <BreadCrum page={"aboutus"} title={"About Us"} />

      {loaded ? (
        // Render child components when data is loaded
        <>
          <Reveal>
            <About data={data?.aboutcompany?.aboutCompanyFields} />
          </Reveal>
          <Reveal>
            <Vision data={data?.vision?.visionMissionFields} />
          </Reveal>
          <Reveal>
            <Mission data={data?.mission?.visionMissionFields} />
          </Reveal>
          <Reveal>
            <Leadership headings={data?.leadership?.titleFields} />
          </Reveal>{" "}
        </>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}

      <Footer />
    </PageTransition>
  );
};

// Export the AboutUs component as the default export
export default AboutUs;
