// Import necessary components and libraries
import Header from "../components/Header";
import AboutCompany from "../components/AboutCompany";
import LandingPage from "../components/LandingPage";
import axios from "axios";
import { useState, useEffect } from "react";
import WhyChooseUs from "../components/WhyChooseUs";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import contactimg from "../images/contactus.png";
import Testimonials from "../components/Testimonials";
import Reveal from "../components/Reveal";
import PageTransition from "../components/PageTransition";
import Spinner from "../components/Spinner";

// Define the Home functional component
const Home = () => {
  // State to hold the fetched data
  const [data, setData] = useState({});
  // State to track whether data is loaded
  const [loaded, setLoaded] = useState(false);

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Fetch data from the API endpoint
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/home",
        );

        // Create a modified object to store data by component key
        const modifiedres = {};
        res.data?.forEach((dat) => {
          const key = dat.component;
          modifiedres[key] = { ...dat };
        });

        // Update state with the fetched data and set loaded to true
        setData(modifiedres);
        setLoaded(true);
      } catch (err) {
        // Handle errors
        console.error(err);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Cleanup function to clear data when the component unmounts
    return () => {
      setData({});
    };
  }, []);

  // Render the component
  return (
    <PageTransition>
      {/* Render the Header component with the "home" page */}
      <Header page={"home"} />
      {loaded ? (
        // Render content when data is loaded
        <>
          {/* Render the LandingPage component with data from landingpage */}
          <LandingPage data={data?.landingpage?.landingPageFields} />
          {/* Render the WhyChooseUs component with data from whychooseus */}
          <Reveal>
            <WhyChooseUs data={data?.whychooseus?.whyChooseUsFields} />
          </Reveal>
          {/* Render the AboutCompany component with data from aboutcompany */}
          <Reveal>
            <AboutCompany data={data?.aboutcompany?.aboutCompanyFields} />
          </Reveal>
          {/* Render the Testimonials component with data from testimonials */}
          <Reveal>
            <Testimonials headings={data?.testimonials?.titleFields} />
          </Reveal>
          {/* Render the ContactForm component and an image */}
          <Reveal>
            <div className="my-4 container d-flex justify-content-center">
              <img
                src={contactimg}
                className="d-none d-lg-block col-lg-3 p-2 img-fluid"
              />
              <ContactForm />
            </div>
          </Reveal>
        </>
      ) : (
        // Display a loading spinner while data is being fetched
        <Spinner />
      )}
      {/* Render the Footer component */}
      <Footer />
    </PageTransition>
  );
};

// Export the Home component as the default export
export default Home;
