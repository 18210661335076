// Functional component 'ContactInfo' that takes a 'data' prop
const ContactInfo = ({ data }) => {
  // Rendered component with dynamic content from 'data' prop
  return (
    <div className="pt-lg-4 my-5 my-lg-0 col-12 col-md-9 col-lg-5 mx-auto">
      {/* Contact heading with dynamic content from 'data' prop */}
      <h2 className="contact-heading mb-4">{data?.heading}</h2>

      {/* Container with background color, rounded corners, and padding */}
      <div className="row bg-theme rounded-4 py-3 px-md-4 px-2">
        {/* Address information */}
        <div className="mb-2">
          <p className="contact-subheading">Address :</p>
          {/* Address content with dangerously set HTML from 'data' prop */}
          <div
            className="address contact-info"
            dangerouslySetInnerHTML={{ __html: data?.address }}
          ></div>
        </div>

        {/* E-Mail information */}
        <div className="mb-2">
          <p className="contact-subheading">E-Mail :</p>
          <p className="contact-info"> {data?.email} </p>
        </div>

        {/* Mobile/Whatsapp number information */}
        <div className="mb-2">
          <p className="contact-subheading">Mobile/Whatsapp No :</p>
          <p className="contact-info">{data?.mobile}</p>
        </div>
      </div>
    </div>
  );
};

// Export the ContactInfo component as the default export
export default ContactInfo;
