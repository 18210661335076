// Importing necessary dependencies from React and Axios
import axios from "axios";
import { useState, useEffect } from "react";

// Functional component 'Leadership' with a 'headings' prop
const Leadership = ({ headings }) => {
  // State variable to store leadership data
  const [data, setData] = useState([]);

  // useEffect to fetch leadership data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/leadership",
        );

        // Update state with fetched data
        setData(res.data);
      } catch (err) {
        // Handle error if any
        console.error(err);
      }
    };

    fetchData();

    // Cleanup function to reset data when the component unmounts
    return () => {
      setData([]);
    };
  }, []);

  // useEffect to initialize and start the Bootstrap carousel after rendering
  useEffect(() => {
    // Function to initialize and start the Bootstrap carousel
    function initializeCarousel() {
      const myCarousel = new window.bootstrap.Carousel(
        document.getElementById("carousel3"),
        {
          interval: 4000,
        },
      );
      myCarousel.cycle();
    }

    // Call the function to initialize the carousel
    initializeCarousel();
  }, []);

  // Rendered component containing the leadership section with a carousel
  return (
    <div className="mt-3 mt-md-4 bg-theme">
      <div className="container-fluid p-3 p-md-4">
        <div className="text-center">
          {/* Heading and subheading for the leadership section */}
          <p className="heading">{headings?.heading}</p>
          <p className="subheading">{headings?.title}</p>
        </div>

        {/* Bootstrap Carousel component for displaying leadership information */}
        <div
          id="carousel3"
          className="carousel mt-4 mt-sm-5 slide"
          data-bs-ride="carousel"
        >
          {/* Carousel indicators */}
          <div className="carousel3 carousel-indicators">
            {data?.map((dat, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carousel3"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current="true"
                aria-label={"Slide" + (index + 1)}
              ></button>
            ))}
          </div>

          {/* Carousel inner container with leadership details */}
          <div className="carousel-inner  leadership-height ">
            {data?.map((dat, index) => (
              <div
                key={index}
                className={`h-100 carousel-item ${index === 0 && "active"}`}
              >
                <div className="h-100 d-block col-lg-10 mx-auto">
                  <div className="row  ">
                    {/* Leader's image */}
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${dat.image}`}
                      className="col-8 mx-auto col-sm-4 img-fluid p-2 p-lg-4 rounded-1"
                    />

                    {/* Leader's details and content */}
                    <div className="col-12 col-sm-8 p-3 p-lg-4 ">
                      <div className="text-center text-sm-start">
                        <h4 className="leadership-name">{dat?.name}</h4>
                        <h4 className="leadership-designation mb-4">
                          {dat?.designation}
                        </h4>
                      </div>
                      {/* Leader's content with HTML rendering */}
                      <div
                        className="paragraph-text"
                        dangerouslySetInnerHTML={{ __html: dat.content }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Carousel navigation controls */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carousel3"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carousel3"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Export the Leadership component as the default export
export default Leadership;
