import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";

// AdminNavbar component for the admin panel navigation
const AdminNavbar = ({ page }) => {
  // Use the useNavigate hook from react-router-dom
  let navigate = useNavigate();

  return (
    <div
      className="col-md-3 bg-dark shadow sticky-top"
      style={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <div className="container p-2">
        {/* Logo for the admin panel */}
        <img
          src={logo}
          alt="Expert Claims"
          className="img-fluid p-2 rounded-4 mb-5"
        />

        {/* Navigation buttons for different sections in the admin panel */}
        <div className="d-flex flex-column gap-3">
          <button
            className={page === "home" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/home")}
          >
            Home
          </button>
          <button
            className={page === "scrollings" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/home/scrollings")}
          >
            Scrollings
          </button>
          <button
            className={page === "testimonials" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/home/testimonials")}
          >
            Testimonials
          </button>
          <button
            className={page === "aboutus" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/aboutus")}
          >
            About Us
          </button>
          <button
            className={page === "leadership" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/aboutus/leadership")}
          >
            Leadership
          </button>
          <button
            className={page === "services" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/services")}
          >
            Services
          </button>
          <button
            className={page === "blogs" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/blogs")}
          >
            Blogs
          </button>
          <button
            className={page === "faqs" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/faqs")}
          >
            Faq's
          </button>
          <button
            className={page === "careers" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/careers")}
          >
            Careers
          </button>
          <button
            className={page === "contactus" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/contactus")}
          >
            Contact Us
          </button>

          {/* Additional navigation button for Breadcrums */}
          <button
            className={page === "breadcrums" ? "admin-btn2" : "admin-btn"}
            onClick={() => navigate("/admin/breadcrums")}
          >
            Breadcrums
          </button>
        </div>
      </div>
    </div>
  );
};

// Export the AdminNavbar component
export default AdminNavbar;
