// Import necessary dependencies and components
import { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import About from "../../components/Admin/About";
import VisionMission from "../../components/Admin/VisionMission";
import PageTransition from "../../components/PageTransition";

// Define the AboutUs component
const AboutUs = () => {
  // State variables for data, selected section, and loading status
  const [data, setData] = useState({});
  const [selected, setSelected] = useState("about");
  const [loaded, setLoaded] = useState(false);
  const [rerenderCount, setRerenderCount] = useState(0);

  const handleRerender = () => {
    // Increment the rerender count to trigger a rerender
    setRerenderCount((prevCount) => prevCount + 1);
  };
  // Fetch data on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/aboutus",
        );

        // Transform data into a more accessible format
        const modifiedres = {};
        res.data?.forEach((dat) => {
          const key = dat.component;
          modifiedres[key] = { ...dat };
        });

        // Set the loaded state and data
        setLoaded(true);
        setData(modifiedres);
      } catch (err) {
        console.error(err);
      }
    };

    // Invoke the fetchdata function
    fetchdata();

    // Cleanup function
    return () => {
      setData({});
    };
  }, [rerenderCount]);

  // Render the component when data is loaded, otherwise return null
  return loaded ? (
    <PageTransition>
      <div className="container-fluid">
        <div className="row">
          {/* Admin Navbar component */}
          <AdminNavbar page={"aboutus"} />
          <div className="col mt-2 p-4">
            {/* Buttons to switch between different sections */}
            <div class="d-flex gap-4">
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("about")}
              >
                {data?.aboutcompany?.aboutCompanyFields?.heading}
              </button>
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("vision")}
              >
                {data?.vision?.visionMissionFields?.heading}
              </button>
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("mission")}
              >
                {data?.mission?.visionMissionFields?.heading}
              </button>
              <button
                type="button"
                class="admin-btn"
                onClick={() => setSelected("leadership")}
              >
                {data?.leadership?.titleFields?.heading}
              </button>
            </div>
            {/* Render the selected section */}
            <div className="mt-5">
              {selected === "about" && (
                <About
                  data={data?.aboutcompany?.aboutCompanyFields}
                  page={"aboutus"}
                  onRerender={handleRerender}
                />
              )}
              {selected === "vision" && (
                <VisionMission
                  data={data?.vision?.visionMissionFields}
                  component={"vision"}
                  onRerender={handleRerender}
                />
              )}
              {selected === "mission" && (
                <VisionMission
                  data={data?.mission?.visionMissionFields}
                  component={"mission"}
                  onRerender={handleRerender}
                />
              )}
              {selected === "leadership" && (
                <Leadership
                  data={data?.leadership?.titleFields}
                  onRerender={handleRerender}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  ) : null;
};

// Leadership component for editing leadership details
const Leadership = ({ data, onRerender }) => {
  // State variables for alert type, form data, and loading status
  const [alert, setAlert] = useState("none");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Send a PUT request to update leadership details
      await axios.put(
        process.env.REACT_APP_API_URL + `/api/aboutus/leadership`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );

      // Set success alert and reset after 3 seconds
      setAlert("success");
      onRerender();
      setTimeout(() => {
        setAlert("none");
      }, 3000);
    } catch (e) {
      // Set failed alert and reset after 3 seconds
      setAlert("failed");
      setTimeout(() => {
        setAlert("none");
      }, 3000);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Render the Leadership form
  return (
    <div className="container mt-4 border p-3 shadow">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            {/* Form fields for editing leadership details */}
            <h2 className="mb-5 fw-bold">About Us / {data?.heading}</h2>
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Heading:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.heading}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heading: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group m-4">
              <label className="fw-semibold fs-5 form-label">Title:</label>
              <input
                className="form-control"
                type="text"
                name="title"
                defaultValue={data?.title}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  })
                }
              />
            </div>

            {/* Display success or failure alerts */}
            {alert === "success" && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {data?.heading} Headings modified Successfully!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}
            {alert === "failed" && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                Unsuccessful please try again!
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlert("none")}
                ></button>
              </div>
            )}

            {/* Button to submit the form */}
            <div className="d-flex justify-content-center">
              <button
                className="admin-btn m-4 "
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// Export the AboutUs component as the default export
export default AboutUs;
