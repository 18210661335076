import mail from "../images/gmail.png";
import whatsapp from "../images/whatsapp.png";
import axios from "axios";
import { useState, useEffect } from "react";

// WhyChooseUs component displays a section with a carousel of scrolling data
// and additional information, including contact options.
const WhyChooseUs = ({ data }) => {
  // State to store scrolling data
  const [scrollingData, setScrollingData] = useState([]);

  // Fetch scrolling data from the server on component mount
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + "/api/scrollings",
        );
        setScrollingData(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchdata();

    // Cleanup function to clear scrollingData on component unmount
    return () => {
      setScrollingData([]);
    };
  }, []);

  // Initialize the Bootstrap Carousel for scrolling data
  useEffect(() => {
    function initializeCarousel() {
      const myCarousel = new window.bootstrap.Carousel(
        document.getElementById("carousel1"),
        {
          interval: 5000,
        },
      );
      myCarousel.cycle();
    }
    initializeCarousel();
  }, []);

  return (
    <div className="mt-3 mt-md-5">
      {/* Display the heading and subheading */}
      <div className="container p-3 p-md-4">
        <p className="heading">{data?.heading}</p>
        <p className="subheading">{data?.title}</p>
      </div>

      {/* Carousel section for scrolling data */}
      <div className="container-fluid">
        <div className="py-3 px-1 row bg-theme">
          <div
            id="carousel1"
            className="col-12 col-lg-11 mx-auto carousel slide"
            data-bs-ride="carousel"
          >
            {/* Carousel indicators */}
            <div className="carousel1 carousel-indicators">
              {scrollingData?.map((dat, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    data-bs-target="#carousel1"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label={"Slide" + (index + 1)}
                  ></button>
                );
              })}
            </div>

            {/* Carousel inner content */}
            <div className="carousel-inner carousel-height ">
              {scrollingData?.map((dat, index) => {
                return (
                  <div
                    key={index}
                    className={`h-100 carousel-item ${index === 0 && "active"}`}
                  >
                    <div className="h-100 d-block w-100 ">
                      <div className="h-100 d-flex justify-content-center align-items-center">
                        <div className="bg-white rounded-3 col-lg-10 px-md-5 px-3 py-3">
                          <div className="row align-items-center row-gap-4">
                            {/* Display the image */}
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${dat.image}`}
                              className="img-fluid col-6 col-sm-3 mx-auto"
                              alt="Scrolling Image"
                            />

                            {/* Display the scrolling text */}
                            <div
                              className="scrolling-text col-12 col-sm-9"
                              dangerouslySetInnerHTML={{ __html: dat.content }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Additional information and contact options */}
      <div className="container px-3 px-md-4 my-4">
        <div className="row">
          <div
            className="col-12 col-md-10 paragraph-text fw-semibold"
            dangerouslySetInnerHTML={{ __html: data?.content }}
          ></div>
        </div>

        {/* Contact options */}
        <div className="mt-2 d-flex justify-content-center gap-md-5 flex-wrap">
          {/* Mail contact option */}
          <div className="d-flex align-items-center gap-2">
            <h5 className="text-reach">Reach Us </h5>
            <h5 className="paragraph-text fw-semibold">On</h5>
            <h5
              className="mail-text"
              onClick={() =>
                window.open(
                  `https://mail.google.com/mail/?view=cm&fs=1&to=support@expertclaims.co.in`,
                  "_blank",
                )
              }
            >
              {"Mail  "}
              <img src={mail} className="image-contacts" alt="Mail" />
            </h5>
          </div>

          {/* WhatsApp contact option */}
          <div className="d-flex align-items-center gap-2">
            <h5 className="text-reach ">Contact Us </h5>
            <h5 className="paragraph-text fw-semibold">On</h5>
            <h5
              className="whatsapp-text"
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?phone=+918309457346&text=Hello`,
                  "_blank",
                )
              }
            >
              {"Whatsapp  "}
              <img src={whatsapp} className="image-contacts" alt="WhatsApp" />
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
