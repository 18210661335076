// Importing necessary dependencies from React and Framer Motion
import React from "react";
import { motion } from "framer-motion";

// Functional component 'PageTransition' with a 'children' prop
const PageTransition = ({ children }) => {
  // Rendered component applying Framer Motion animations to the children
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }} // Initial animation state (opacity and y-axis position)
      animate={{ opacity: 1, y: 0 }} // Animation state when component is mounted
      exit={{ opacity: 0, y: 20 }} // Animation state when component is unmounted
      transition={{ duration: 0.5 }} // Animation duration
    >
      {children}
    </motion.div>
  );
};

// Export the PageTransition component as the default export
export default PageTransition;
