// Importing the 'useNavigate' hook from the 'react-router-dom' library
import { useNavigate } from "react-router-dom";

// Functional component 'AboutCompany' that takes a 'data' prop
const AboutCompany = ({ data }) => {
  // Initializing the 'navigate' function using the 'useNavigate' hook
  let navigate = useNavigate();

  return (
    // Main container with background color and padding styles
    <div className="bg-theme py-4 py-md-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Left column containing text content */}
          <div className="px-md-5 px-4 col-12 col-lg-6 order-2 order-lg-1">
            {/* Heading section with dynamic content from 'data' prop */}
            <p className="heading">{data?.heading}</p>

            {/* Subheading section with dynamic content from 'data' prop */}
            <p className="subheading mb-4">{data?.title}</p>

            {/* Paragraph text section with dangerously set HTML from 'data' prop */}
            <div
              className="paragraph-text mb-4 mb-md-5"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>

            {/* Button section to navigate to "/aboutus" on click */}
            <div className="d-flex justify-content-center">
              <button
                className="button-about"
                onClick={() => navigate("/aboutus")}
              >
                Read More
              </button>
            </div>
          </div>

          {/* Right column containing an image */}
          <div className="col-12 col-md-9 col-lg-6 p-4 order-1 order-lg-2 mx-auto">
            {/* Image with a dynamic source from 'data' prop */}
            <img
              src={`${process.env.REACT_APP_API_URL}/${data?.image}`}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the AboutCompany component as the default export
export default AboutCompany;
