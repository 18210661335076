// About component takes a 'data' prop, which is an object containing information to display
const About = ({ data }) => {
  return (
    // Main container with margin and padding styles
    <div className="container-md mt-3 mt-md-4 p-md-4 p-3 clearfix">
      {/* Heading section with dynamic content from 'data' prop */}
      <p className="heading">{data?.heading}</p>

      {/* Image section with margin, size classes, and a dynamic image source */}
      <div className="ms-lg-5 mb-3 col-12 col-md-10 col-lg-5 float-lg-end">
        <img
          src={`${process.env.REACT_APP_API_URL}/${data?.image}`}
          className="img-fluid"
        />
      </div>

      {/* Title section with dynamic content from 'data' prop */}
      <p className="col-lg-6 subheading">{data?.title}</p>

      {/* Content section with margin */}
      <div className="mt-4">
        {/* Paragraph text section with dangerously set HTML from 'data' prop */}
        <div
          className="paragraph-text"
          dangerouslySetInnerHTML={{ __html: data?.content }}
        ></div>
      </div>
    </div>
  );
};

// Export the About component as the default export
export default About;
