// Importing the 'Reveal' component from the specified path
import Reveal from "../components/Reveal";

// Functional component 'Blog' that takes a 'data' prop
const Blog = ({ data }) => {
  // Function to format a date string
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Extracting day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Formatting the date as DD/MM/YYYY
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;

    return formattedDate;
  }

  return (
    // Wrapping the entire component in the 'Reveal' component
    <Reveal>
      {/* Main container with background color and padding styles */}
      <div className="mb-4 bg-theme pt-3 px-md-4 p-3 pointer rounded-2">
        <div className="col-12 link-blogs">
          {/* Link to open the blog in a new tab on click */}
          <a onClick={() => window.open(`/blogs/${data?.title}`, "_blank")}>
            {/* Blog title section with dynamic content from 'data' prop */}
            <p className="blogs-heading1 mb-4">{data?.title}</p>
          </a>
        </div>
        <div className="row mb-3">
          {/* Left column with blog image */}
          <div className="col-lg-4 col-md-8 mb-3 mb-lg-0">
            {/* Image with a dynamic source from 'data' prop */}
            <img
              src={`${process.env.REACT_APP_API_URL}/${data?.image1}`}
              className="img-fluid"
            />
          </div>
          {/* Right column with blog introduction */}
          <div className="col-lg-7 col-md-10">
            {/* Blog intro section with dangerously set HTML from 'data' prop */}
            <div
              className="blogs-intro"
              dangerouslySetInnerHTML={{ __html: data?.intro }}
            ></div>
            <div className="mt-2">
              {/* Link to open the blog in a new tab on click */}
              <a
                className="link-blogs"
                onClick={() => window.open(`/blogs/${data?.title}`, "_blank")}
              >
                read more...
              </a>
            </div>
          </div>
        </div>
        {/* Row for displaying author and creation date information */}
        <div className="row  d-flex justify-content-between">
          {/* Author information */}
          <p className="col-8  col-md-2 blogs-info">{data?.author}</p>
          {/* Creation date information with formatting using 'formatDate' function */}
          <p className="col-4  text-end col-md-2 blogs-info">
            {formatDate(data?.createdAt)}
          </p>
        </div>
      </div>
    </Reveal>
  );
};

// Export the Blog component as the default export
export default Blog;
